import {
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Page from '../components/Page';
import { getItem, removeItem, setItem } from '../utils/localStorage';

import { INotFoundParams } from '../enum';
import { AppColors, config } from '../utils/utils';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getPlanLabel, getSubscriptionPrice, openLinkOnNewTab, showErrorMessage } from '../utils/utilMethods';
import {
  findAffiliateGroup,
  findAffiliateGroupPricing,
} from '../store/AffiliateGroup/api';
import {
  AffiliateGroupPricing,
  AffiliateGroup,
} from '../store/AffiliateGroup/api/interface';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import PurchaseModel from '../components/modal/PurchaseModel';
import useToggle from '../hooks/useToggle';

const KnoCardCart = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams<INotFoundParams>();
  let planType = getItem(config.SLECTED_PLAN_KEY);
  let isAnnually = getItem(config.SLECTED_PLAN_IS_ANNUALLY_KEY);

  let isPurchaseSelectedPlan = getItem(config.REQUIRED_SUBSCRIPTION_KEY);

  const { user, accessToken } = useAppSelector((state) => state?.auth);
  const { toggle, onOpen, onClose } = useToggle();

  let [isKnoCardLinkSelected, setIsKnoCardLinkSelected] = useState(getItem(config.IS_KNOCARD_LINK_SELECTED_KEY));

  const { data: affiliateGroupList,
    isLoading: isAffiliateGroupLoading,
    affiliateGroupPricing,
    isPricingLoading } = useAppSelector((state) => state.affiliateGroup);

  const [selectedAffiliateGroup, setSelectedAffiliateGroup] = useState<AffiliateGroup | undefined>()

  const [selectedAffiliateGroupPricing, setSelectedAffiliateGroupPricing] = useState<AffiliateGroupPricing | undefined>()

  let planPrice = getSubscriptionPrice(planType, isAnnually, selectedAffiliateGroupPricing) + ((isKnoCardLinkSelected && planType == config.PRO_PRO && !isAnnually) ? getSubscriptionPrice(config.KNOCARD_LINK, isAnnually, selectedAffiliateGroupPricing) : 0);

  useEffect(() => {
    if (isPricingLoading === false && affiliateGroupPricing.length === 0) {
      dispatch(findAffiliateGroupPricing());
    }

    if (isAffiliateGroupLoading === false && affiliateGroupList.length === 0) {
      dispatch(findAffiliateGroup());
    }


    let planBilling = searchParams.get('pb');
    let paramPlanType = searchParams.get('pt');

    if (paramPlanType && [config.PRO_1, config.PRO_PRO, config.PRO_BASIC].includes(paramPlanType)) {
      planType = paramPlanType;
      setItem(config.SLECTED_PLAN_IS_ANNUALLY_KEY, (planBilling == "annually"));
      setItem(config.SLECTED_PLAN_KEY, planType);
    }


    if (!([config.PRO_1, config.PRO_PRO, config.PRO_BASIC].includes(planType))) {
      navigate(`/subscription/${params?.username ?? ''}`);
    }


    if (user?.current_subscription?.status?.toLowerCase() == "active" && user?.current_subscription?.code?.code?.toLowerCase()?.includes("pro")) {
      if (user?.current_subscription?.code?.code == config.PRO_1) {
        navigate('/dashboard');
      }

    } else if (user && [config.PRO_1, config.PRO_PRO, config.PRO_BASIC, config.PRO_PLUS].includes(planType) && isPurchaseSelectedPlan) {
      removeItem(config.IS_PURCHASE_SELECTED_PLAN_KEY)
      handlePurchase();
    }
  }, []);



  useEffect(() => {
    let isPricingSet = false;
    if (affiliateGroupList.length > 0) {
      let selectedId = getItem(config.GROUP_NETWORK_ID_KEY);

      if (selectedId != null) {
        affiliateGroupList.forEach((item) => {
          if (`${item.id}` == selectedId) {
            if (item.group_pricing != undefined) {
              setSelectedAffiliateGroup(item);
              setSelectedAffiliateGroupPricing(item.group_pricing);
              isPricingSet = true;
            }
          }
        });
      }
    }

    affiliateGroupPricing.forEach((item) => {
      if (item.is_default == 1) {
        if (!isPricingSet) {
          setSelectedAffiliateGroupPricing(item);
          isPricingSet = true;
        }
      }
    });

    if (!isPricingSet) {
      setSelectedAffiliateGroupPricing(undefined);
    }
  }, [affiliateGroupList, affiliateGroupPricing]);

  const showPlanSelectionError = (plan: string) => {
    showErrorMessage(`You have already subscribed ${plan} plan.`);
  }

  const handlePurchase = () => {
    if ((user?.current_subscription?.code?.code != planType) || (user?.current_subscription?.code?.code != config.PRO_1 && planType == config.PRO_1) || (user?.current_subscription?.status != 'active')) {
      if ((user?.current_subscription?.code?.code == config.PRO_FREE_TRIAL && planType == config.PRO_BASIC && (user?.current_subscription?.status == 'active'))) {
        showPlanSelectionError(getPlanLabel(user?.current_subscription?.code?.code ?? ""));
        return;
      }

      onOpen();
    } else {
      showPlanSelectionError(getPlanLabel(user?.current_subscription?.code?.code ?? ""));
    }
  };

  const handleTapNext = () => {
    
    if (user && accessToken) {
      handlePurchase();
    } else {
      navigate(`/register/${params?.username ?? ''}`);
    }
  }

  const handleChangePlan = () => {
    navigate(`/subscription/${params?.username ?? ''}`);
  }

  const handleLearnMoreKnoCardLink = () => {
    openLinkOnNewTab(`${config.BASE_URL}/knocard-link`)
  }

  const handleAddKnoCardLinkPlan = () => {
    setItem(config.IS_KNOCARD_LINK_SELECTED_KEY, true);
    setIsKnoCardLinkSelected(true);
  }

  const handleRemoveKnoCardLinkPlan = () => {
    removeItem(config.IS_KNOCARD_LINK_SELECTED_KEY)
    setIsKnoCardLinkSelected(false);
  }

  return (
    <>
      <Page
        title='Subscription | KnoCard'
        showAppBar={true}
        sx={{ minHeight: "100vh", backgroundColor: AppColors.backgroundColor }}
      >
        <AppBar
          position='sticky'
          sx={{
            backgroundImage: 'url("/static/images/header_bg.svg")',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            minHeight: '60px'
          }}
        >
          <Stack
            flexDirection={'row'}
            sx={{ position: 'relative' }}
          >
            <Box
              component={'img'}
              src={selectedAffiliateGroup?.brand_logo_link ?? '/static/images/white_logo.svg'}
              sx={{
                maxHeight: '60px',
                ml: 2,
                pt: 1.5,
                pb: 1,
              }}
            ></Box>

          </Stack>
        </AppBar>


        <Box sx={{ background: `${AppColors.backgroundColor}` }}>
          <Box>
            <Grid pt={5} container justifyContent={'center'}>
              <Grid xs={10} item>
                <Typography sx={{ fontWeight: 'bold', fontSize: "1.2rem", color: AppColors.blackColor }}>My Cart</Typography>
              </Grid>
            </Grid>
            <Grid container
              gap={{ md: 5, xs: 0 }}
              pb={10} justifyContent={'center'}>

              <Grid
                item
                xs={11}
                sm={11}
                md={5}
              >
                <Card
                  sx={{ borderRadius: 4, mt: 2 }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        width: '100%',
                      }}>
                      <Typography sx={{ fontWeight: '500', color: AppColors.blackColor }}>KnoCard{' '}
                        {
                          planType == config.PRO_1 ? getPlanLabel(config.PRO_1) : planType == config.PRO_PRO ? getPlanLabel(config.PRO_PRO) : planType == config.PRO_BASIC ? getPlanLabel(config.PRO_BASIC) : planType == config.PRO_PLUS ? getPlanLabel(config.PRO_PLUS)
                            : getPlanLabel(config.FREE)}

                      </Typography>
                      <Box>
                        <IconButton aria-label="edit" onClick={handleChangePlan}>
                          <EditIcon sx={{ color: AppColors.primaryColor }} />
                        </IconButton>

                        <IconButton aria-label="delete" onClick={handleChangePlan}>
                          <DeleteIcon sx={{ color: AppColors.primaryColor }} />
                        </IconButton></Box>
                    </Box>
                    <Box component={'img'} src={"/static/images/knocard.png"} sx={{ ml: 2, mt: 2, height: "80px" }} />
                  </CardContent>
                </Card>

                {(planType == config.PRO_PRO && !isAnnually) && (<Card
                  sx={{ borderRadius: 4, mt: 2 }}
                >
                  <CardContent sx={{ ...(isKnoCardLinkSelected ? {} : { pt: 3 }) }}>
                    <Box sx={{ width: "100%", textAlign: 'center' }}>

                      {isKnoCardLinkSelected ? (<Box sx={{ textAlign: 'left' }}><Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          width: '100%',
                        }}>
                        <Typography sx={{ fontWeight: '500', color: AppColors.blackColor, textTransform: 'capitalize' }}>
                          {
                            getPlanLabel(config.KNOCARD_LINK)}
                        </Typography>
                        <Box>
                          <IconButton aria-label="delete" onClick={handleRemoveKnoCardLinkPlan}>
                            <DeleteIcon sx={{ color: AppColors.primaryColor }} />
                          </IconButton></Box>
                      </Box> <Box component={'img'} src={"/static/images/knocard.png"} sx={{ ml: 2, mt: 2, height: "80px" }} /></Box>) : (<Box >
                        <Button variant="outlined" sx={{
                          color: AppColors.grayThreeColor, fontWeight: "500",
                          fontSize: "1rem",
                          textTransform: "capitalize",
                          borderRadius: "100px"
                        }} startIcon={<AddCircleIcon sx={{ mr: 0.8, color: AppColors.greenColor, float: 'left' }} />}
                          onClick={handleAddKnoCardLinkPlan}
                        >Add KnoCard Link</Button>
                      </Box>)}

                      <Button
                        sx={{
                          background: `linear-gradient(180deg, ${AppColors.primaryColor}, ${AppColors.secondryColor})`,
                          textAlign: 'center',
                          borderRadius: '100px',
                          width: "fit-content",
                          pl: 5,
                          pr: 5,
                          pt: 1,
                          pb: 1,
                          mt: 1
                        }}
                        onClick={handleLearnMoreKnoCardLink}
                      ><Typography sx={{
                        color: "#ffffff", fontWeight: "500",
                        fontSize: "1rem",
                        textTransform: "capitalize"

                      }}>Learn more</Typography>  </Button>
                    </Box>
                  </CardContent>
                </Card>)}
              </Grid>
              <Grid
                item
                xs={11}
                sm={11}
                md={5}
              >
                <Card
                  sx={{ borderRadius: 4, mt: 2 }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        width: '100%',
                      }}>
                      <Typography sx={{ fontWeight: '500', color: AppColors.blackColor }}>Due today</Typography>
                      <Typography sx={{ fontWeight: '500', color: AppColors.primaryColor, fontSize: "1.5rem" }}>${planPrice}</Typography>
                    </Box>
                  </CardContent>
                </Card>
                <Card
                  sx={{ borderRadius: 4, mt: 2 }}
                >
                  <CardContent>
                    <Box
                      sx={{
                        width: '100%',
                      }}>
                      <Typography sx={{ fontWeight: '500', fontSize: "1.2rem", color: AppColors.blackColor }}>Price Detail</Typography>
                      <Typography sx={{ fontWeight: '500', fontSize: "0.9rem", pt: 1, color: AppColors.blackColor }}>Due today</Typography>
                      <Typography sx={{ fontWeight: '500', color: AppColors.primaryColor, fontSize: "1.5rem" }}>${planPrice}</Typography>
                      <Typography sx={{ fontWeight: '400', pt: 1, color: AppColors.grayThreeColor }}>
                        {
                          planType == config.PRO_1 ? "Initial payment, KnoCard 360 with Link" : planType == config.PRO_PRO ? `This is ${isAnnually ? "an annual" : "a monthly"} subscription for KnoCard Pro.` : `This is ${isAnnually ? "an annual" : "a monthly"} subscription for KnoCard Basic.`
                        }


                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
                <Box sx={{ width: "100%", display: "flex", justifyContent: 'end' }}>
                  <Button
                    sx={{
                      background: `linear-gradient(180deg, ${AppColors.primaryColor}, ${AppColors.secondryColor})`,
                      textAlign: 'center',
                      borderRadius: '100px',
                      pl: 5,
                      pr: 5,
                      pt: 1,
                      pb: 1,
                      mt: 2,
                      mb: 3
                    }}

                    onClick={handleTapNext}><Typography sx={{
                      color: "#ffffff", fontWeight: "500",
                      fontSize: "1rem",
                      textTransform: "capitalize"

                    }}>Next</Typography>  </Button>
                </Box>

              </Grid>

            </Grid>
          </Box>

        </Box>
        <PurchaseModel
          purchaseType={planType}
          isAnnuallySubscription={isAnnually}
          toggle={toggle}
          onClose={onClose}
        />
      </Page >

    </>
  );
};
export default KnoCardCart;
