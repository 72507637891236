import { LoadingButton } from '@mui/lab';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, textAlign } from '@mui/system';
import { Transition } from './Transition';
import { useFormik } from 'formik';
import contactFormSchema from '../validation/contactFormSchema';
import { postContactForm } from '../store/contactForm/api';
import { useAppDispatch, useAppSelector } from '../store';
import { useEffect } from 'react';
import { IContactFormState } from '../store/contactForm/api/interface';
import { useNavigate } from 'react-router-dom';
import { getActiveIndex } from '../utils/getActiveIndex';
import { setSc } from '../store/userInfo/userInfoSlice';
import { analytics } from '../store/shareRefer/api';
import { ContactFormSourceType } from '../enum';
import { AppColors } from '../utils/utils';
import PersonIcon from '../assets/profile/person.svg';
import CallIcon from '../assets/profile/call.svg';
import MailIcon from '../assets/profile/mail.svg';

interface IProps {
  toggle: boolean;
  onClose: () => void;
  onSubmitSuccess?: (code: string) => void;
  onSubmitSuccessRedirect: boolean;
  source: ContactFormSourceType;
}

const ContactForm: React.FC<IProps> = ({ toggle, onClose, onSubmitSuccess, onSubmitSuccessRedirect, source }) => {
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector((state) => state.userInfo.data);

  const navigate = useNavigate();

  const textFieldStyle = {
    '& label': {
      color: AppColors.grayThreeColor,
    },
    '& .MuiOutlinedInput-root input': {
      color: AppColors.grayThreeColor,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: ` ${AppColors.strokeColor} !important`,
    },
    '& .MuiFormLabel-root.Mui-focused': {
      color: `${AppColors.primaryColor} !important`,
    },
    '& .MuiInputLabel-shrink': {
      backgroundColor: AppColors.grayZeroColor,
      paddingLeft: '5px',
      paddingRight: '5px',
      borderRadius: '5px',
    },
    '& .MuiOutlinedInput-root': {
      background: AppColors.grayZeroColor,
      '& fieldset': {
        borderColor: AppColors.strokeColor,
      },
      '&:hover fieldset': {
        borderColor: AppColors.strokeColor,
      },
      '&.Mui-focused fieldset': {
        borderColor: AppColors.strokeColor,
      },
    },
  };

  const {
    handleSubmit,
    values,
    resetForm,
    isSubmitting,
    errors,
    touched,
    handleChange,
    setFieldValue,
  } = useFormik<IContactFormState>({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      where_i_met_you: '',
      occupation: '',
      notes: '',
      is_share: 1,
      sender_id: 0,
      phones: []
    },
    validationSchema: contactFormSchema,
    onSubmit: async (values) => {
      try {
        values.phones = [values.phone_number];
        const response = await dispatch(postContactForm({ ...values, source: source })).unwrap();
        if (response && response.data && response.data.data) {


          const sc = response.data.data.contact.contact_code;

          if (onSubmitSuccessRedirect) {
            const result = getActiveIndex(
              parseInt(userInfo?.knocard?.homepage ?? '0'),
              userInfo?.user_features ?? []
            );
            if (result === 0) {
              navigate(`/${userInfo?.username}/contact${sc ? `?sc=${sc}` : ''}`);
            }
            if (result === 1) {
              navigate(`/${userInfo?.username}/media${sc ? `?sc=${sc}` : ''}`);
            }
            if (result === 2) {
              navigate(`/${userInfo?.username}/company${sc ? `?sc=${sc}` : ''}`);
            }
          }

          dispatch(setSc(sc));
          onSubmitSuccess && onSubmitSuccess(sc);
        }
        onClose();
        resetForm();
        setterForSenderId();
      } catch (error: any) { }
    },
  });

  function setterForSenderId() {
    setFieldValue('sender_id', userInfo?.id ?? 0);
  }

  useEffect(() => {
    if (userInfo?.id) {
      setterForSenderId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);
  return (
    <Dialog
      fullWidth
      open={toggle}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        resetForm();
        setterForSenderId();
        onClose();
      }}
      aria-describedby='alert-dialog-slide-description'
    >
      <DialogTitle
        sx={{
          color: AppColors.primaryColor,
          textAlign:{md:"start", xs:"center"}
        }}
      >
        Please Share Your Contact Info
      </DialogTitle>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <DialogContent sx={{padding:"10px 24px 5px 24px"}}>
          <Stack direction='column' spacing={2}>
            <TextField
              id='first_name'
              name='first_name'
              label='First Name*'
              fullWidth
              onChange={handleChange}
              value={values.first_name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Box component={'img'} src={PersonIcon} />
                  </InputAdornment>
                ),
              }
              }
              error={Boolean(errors.first_name && touched.first_name)}
              helperText={
                Boolean(errors.first_name && touched.first_name) &&
                errors.first_name
              }
              sx={{ ...textFieldStyle }}
            />

            <TextField
              id='last_name'
              name='last_name'
              label='Last Name'
              fullWidth
              onChange={handleChange}
              value={values.last_name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Box component={'img'} src={PersonIcon} />
                  </InputAdornment>
                ),
              }
              }
              error={Boolean(errors.last_name && touched.last_name)}
              helperText={
                Boolean(errors.last_name && touched.last_name) &&
                errors.last_name
              }
              sx={{ ...textFieldStyle }}
            />

            <TextField
              type='tel'
              name='phone_number'
              id='phone_number'
              label='Mobile Number*'
              onChange={handleChange}
              value={values.phone_number}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Box component={'img'} src={CallIcon} />
                  </InputAdornment>
                ),
              }
              }
              error={Boolean(errors.phone_number && touched.phone_number)}
              helperText={
                Boolean(errors.phone_number && touched.phone_number) &&
                errors.phone_number
              }
              sx={{ ...textFieldStyle }}
            />

            <TextField
              type='email'
              name='email'
              id='email'
              label='Email Address'
              fullWidth
              value={values.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <Box component={'img'} src={MailIcon} />
                  </InputAdornment>
                ),
              }
              }
              onChange={handleChange}
              error={Boolean(errors.email && touched.email)}
              helperText={
                Boolean(errors.email && touched.email) && errors.email
              }
              sx={{ ...textFieldStyle }}
            />

            <Stack direction="column" spacing={2} justifyContent={"flex-end"} >
              <LoadingButton
                sx={{
                  minWidth: '100px', borderColor: AppColors.greenColor, backgroundColor:AppColors.greenColor, color: "#ffffff", textTransform: "unset", fontWeight: "600",
                  "&:hover": {
                    borderColor: AppColors.greenColor,
                    background:AppColors.greenColor
                  },
                  "& span span": {
                    color: "#ffffff"
                  }
                }}
                type='submit'
                loading={isSubmitting}
                variant='outlined'

              >
                Submit
              </LoadingButton>
              <LoadingButton
                sx={{ minWidth: '80px', color: AppColors.grayThreeColor, textTransform: "unset", fontWeight: "600",mt:"10px !important" }}
                type='reset'
                variant='text'
                onClick={onClose}
              >
                Skip
              </LoadingButton>
            </Stack>
          </Stack>

        </DialogContent>
        <DialogActions sx={{ paddingX: 3, justifyContent: "center" }}>
          <Box component={"img"} src={"/static/images/knocard_logo.png"} sx={{ height: "30px" }}></Box>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ContactForm;
