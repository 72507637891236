
import { Stack, Typography, Box, Grid, useMediaQuery, Card, CardContent, TextField, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider, Button, Fab, Avatar, CircularProgress } from '@mui/material';

import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import useWindowSize from '../utils/useWindowSize';
import { useNavigate, useParams } from 'react-router-dom';
import { FormikProvider, useFormik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { CameraAltOutlined } from '@mui/icons-material';
import { acceptImageFileTypes, acceptVideoFileTypes } from '../constant';
import { IBusinessSocialParams, MediaType, PlatformType } from '../enum';
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd';
import ReorderIcon from '@mui/icons-material/Reorder';
import Iconify from '../components/Iconify';

import { decryptString } from '../utils/url';
import Loader from '../components/Loader';
import { ContentFile } from '../store/social/api/interface';
import businessSocialSchema from '../validation/businessSocialSchema';
import { FaRegComment, FaRegHeart } from 'react-icons/fa';
import ReactReadMoreReadLess from '../components/ReactReadMoreReadLess';
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import YouTube from "react-youtube";
import IosShareIcon from '@mui/icons-material/IosShare';
import { createUpdateSocialFeed, getSocialFeed } from '../store/social/api';
import { socialFeedCreated, socialFeedUpdated } from '../store/social/socialSlice';
import { checkIsKnoCardSocialConnectUserPlan, checkIsProUser } from '../utils/checkPro';
import BlockOverlay from '../components/BlockOverlay';
import useToggle from '../hooks/useToggle';
import { getCapitalizedString, inputTextFieldStyle, showSuccessMessage, urlToFileObject } from '../utils/utilMethods';
import { MediaLibraryItem } from '../store/mediaLibrary/api/interface';
import MediaLibraryView from '../components/imagelibrary/MediaLibraryView';
import HeartIcon from '../assets/heart.svg';
import EyeIcon from '../assets/eye.svg';
import ShareIcon from '../assets/share.svg';
import CommentIcon from '../assets/comment.svg';
import { AppColors } from '../utils/utils';

const opts = {
    height: "100%",
    width: "100%",
    playerVars: {
        // Hide related videos after the video finishes
        rel: 0,
        // Hide the video annotations
        iv_load_policy: 3,
        // Hide the YouTube logo
        modestbranding: 1,
    },
};

interface IInitialState {
    items: ContentFile[];
    title: string;
    description: string;
}

const AddEditBusinessSocial = () => {
    const [isDataLoading, setIsDataLoading] = useState(false);
    const params = useParams<IBusinessSocialParams>();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [changeThumnail, setChangeThumnail] = useState<ContentFile | undefined>(undefined);

    const matches = useMediaQuery('(max-width:745px)');
    const windowSize = useWindowSize();
    const fileUploader = useRef<any>(null);
    const [attachmentCount, setAttachmentCount] = useState<number>(1);
    const [isVideoProcessing, setVideoProcessing] = useState<boolean>(false);

    const [deleteContents, setdeleteContents] = useState<any>([]);
    const userInfo = useAppSelector((state) => state?.auth?.user);
    const isProUser = checkIsProUser(userInfo);
    const isKnoCardSocialConnectUser = checkIsKnoCardSocialConnectUserPlan(userInfo);

    const [isMediaLibraryExpanded, setExpandMediaLibrary] = useState<boolean>(false);

    const [editBusinessSocialPostInfo, setEditBusinessSocialPostInfo] = useState<any>();
    useEffect(() => {
        const businessSocialId = decryptString(params?.bsid ?? "");

        try {


            if (businessSocialId != undefined && businessSocialId.length > 0) {
                setIsDataLoading(true);
                dispatch(getSocialFeed({ feedId: businessSocialId })).unwrap().then((result) => {
                    if (result?.data?.status?.code == "success") {
                        setIsDataLoading(false);
                        setEditBusinessSocialPostInfo(result.data.company_feed);
                        formik.values.title = getCapitalizedString(result.data.company_feed.title);
                        formik.values.description = getCapitalizedString(result.data.company_feed.description);
                        result.data.company_feed.contents?.forEach((item: any, index: number) => {
                            formik.values.items.push({
                                type: item.type,
                                title: getCapitalizedString(item.title ?? ""),
                                description: getCapitalizedString(item.description ?? ""),
                                link: item.link,
                                thumbnail_link: item.thumbnail,
                                platform: item.platform,
                                contents: item
                            });
                        });

                        setAttachmentCount(Math.random() + Math.random());
                    } else {
                        navigate(`/dashboard/business-social`);
                    }
                });
            }

        } catch (error) {

        }
    }, []);

    const {
        toggle: toggleProView,
        onOpen: onOpenProView,
        onClose: onCloseProView,
    } = useToggle();

    const formik = useFormik<IInitialState>({
        initialValues: {
            items: [],
            title: '',
            description: ''
        },
        onSubmit: async (values) => {
            try {


                /*if (!(values.items.length > 0) && isProUser) {
                    showErrorMessage(`At least, one image or video is required.`);
                    return;
                }*/

                var result = await dispatch(createUpdateSocialFeed({
                    feedId: editBusinessSocialPostInfo?.id,
                    companyId: userInfo?.company?.id ?? 0,
                    title: getCapitalizedString(values.title.trim()),
                    description: getCapitalizedString(values.description.trim()),
                    mediaData: values.items,
                    deleteContents: deleteContents,
                    userId: userInfo?.id ?? 0
                })).unwrap();
                if (result?.data?.status == "success") {

                    dispatch(editBusinessSocialPostInfo?.id != null ? socialFeedUpdated(result?.data?.data.company_feed) : socialFeedCreated(result?.data?.data.company_feed));
                    showSuccessMessage(`Business Post ${editBusinessSocialPostInfo?.id != null ? 'saved' : 'created'} successfully.`);
                    navigate('/dashboard/business-social')
                }
            } catch (e) { }

        },
        validationSchema: businessSocialSchema,
    });

    const onCancel = () => {
        navigate('/dashboard/business-social')
    }


    function selectMedia(isChangeThumNail?: boolean) {
        if (isChangeThumNail != true) {
            setChangeThumnail(undefined);
        }
        fileUploader?.current?.click();
    }

    const onFileChange = async (event: any) => {

        if (changeThumnail == undefined) {
            Array.from(event.target.files).forEach((file: any, index: number) => {
                const fileType = acceptVideoFileTypes.includes(file.type) ? MediaType.video : MediaType.image;
                if (fileType == MediaType.video) {
                    setVideoProcessing(true);
                    const videoURL = URL.createObjectURL(file);

                    // Create a video element to capture a frame for the thumbnail
                    const videoElement = document.createElement('video');
                    videoElement.src = videoURL;

                    // Wait for metadata to load, then capture a frame
                    videoElement.onloadedmetadata = () => {
                        videoElement.currentTime = 5; // Seek to 5 seconds (you can adjust this)
                        videoElement.onseeked = () => {
                            const canvas = document.createElement('canvas');
                            canvas.width = videoElement.videoWidth;
                            canvas.height = videoElement.videoHeight;
                            const context = canvas.getContext('2d');
                            context?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

                            canvas.toBlob((blob) => {

                                if (blob != null) {
                                    const thumbnailBlob: any = new Blob([blob], { type: 'image/jpeg' }); // You can adjust the type as needed
                                    thumbnailBlob.lastModifiedDate = new Date();
                                    thumbnailBlob.name = `${Date.now()}.jpeg`;

                                    addMediaObject({
                                        file: file,
                                        thumbnail: thumbnailBlob as File,
                                        type: fileType,
                                        title: '',
                                        description: '',
                                        platform: PlatformType.local,
                                    });
                                }
                                // Clean up
                                URL.revokeObjectURL(videoURL);
                                videoElement.remove();
                                setVideoProcessing(false);
                            }, 'image/jpeg', 0.6);

                        }
                    }
                } else {
                    addMediaObject({
                        file: file,
                        type: fileType,
                        title: '',
                        description: '',
                        platform: PlatformType.local,
                    });

                }
            })
        } else {
            let array = [...formik.values.items];
            let index = array.indexOf(changeThumnail!);
            if (index !== -1 && event.target.files.length > 0) {
                changeThumnail.thumbnail = event.target.files[0];
                formik.values.items[index] = changeThumnail;
            }
            setChangeThumnail(undefined);
        }

    };


    const addMediaObject = async (contentFile: ContentFile) => {

        formik.values.items.push(contentFile);
        setAttachmentCount(Math.random() + Math.random());
    }


    const onDragEnd = (result: DropResult) => {

        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            formik.values.items,
            result.source.index,
            result.destination.index
        );
        formik.values.items = items;
        setAttachmentCount(Math.random() + Math.random());
    }

    const removeMediaObject = (contentFile: ContentFile) => {

        if (contentFile.contents != null) {
            setdeleteContents([...deleteContents, contentFile.contents.id])
        }

        let array = [...formik.values.items];
        let index = array.indexOf(contentFile);
        if (index !== -1) {
            array.splice(index, 1);
            formik.values.items = array;
        }
        setAttachmentCount(Math.random() + Math.random());
    }


    const reorder = (list: any[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };



    const changeThumbnailClicked = (contentFile: ContentFile) => {
        setChangeThumnail(contentFile);
        selectMedia(true);
    }


    const handleOnMediaSelectFromMediaLibrary = async (mediaLibraryItem: MediaLibraryItem) => {


        setExpandMediaLibrary(false);

        if (isProUser || isKnoCardSocialConnectUser) {
            if (mediaLibraryItem.link != null && mediaLibraryItem.filename != null) {
                setVideoProcessing(true)
                addMediaObject({
                    type: mediaLibraryItem.type ?? "",
                    title: mediaLibraryItem.title ?? "",
                    description: '',
                    link: mediaLibraryItem.link,
                    thumbnail_link: mediaLibraryItem.thumbnail,
                    platform: PlatformType.asset,
                });


                setVideoProcessing(false);
            }
        } else {
            onOpenProView();
        }
    };

    return (
        <>
            <Page title='KnoCard Social | KnoCard' showAppBar={true}>
                <form onSubmit={formik.handleSubmit}>
                    <FormikProvider value={formik}>
                        <Stack>
                            {isDataLoading ? <Loader /> : <Grid container marginTop={6}>


                                <Grid xs={12} md={6} item>
                                    <Card
                                        key={"card-preview-social-post"}
                                        sx={{
                                            margin: "3%",
                                            p: 0
                                        }}
                                        elevation={0}
                                    >
                                        <Stack direction="column" gap={1}>
                                            <Box display="flex" alignItems="center" height="60px" sx={{ pl: 2.5, pr: 2, pt: 1 }}>
                                                <Box width="50px">
                                                    <Avatar alt="pic" src={userInfo?.profile_picture ?? "/static/images/placeholder_profile.png"} />
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="flex-start"
                                                    justifyContent="center"
                                                    flex={1}
                                                >
                                                    <Typography
                                                        variant='h6'
                                                        component='div'
                                                        color='black'
                                                        className='post-main-title'
                                                        sx={{ color: AppColors.blackColor }}
                                                    >
                                                        {userInfo?.name ?? ""}
                                                    </Typography>

                                                    <Typography
                                                        variant='h6'
                                                        component='div'
                                                        fontSize='10px'
                                                        className='post-main-sub-title'
                                                        sx={{ color: AppColors.grayTwoColor }}
                                                    >
                                                        {userInfo?.occupation ?? ""}
                                                    </Typography>
                                                </Box>
                                            </Box>

                                            {formik.values.items.length > 0 && (
                                                <Splide
                                                    aria-label="Images"
                                                    options={{
                                                        perPage: 1,
                                                        //fixedHeight: 390,
                                                    }}
                                                >
                                                    {formik.values.items.map((content, index) => {
                                                        return content?.type === MediaType.image ? (
                                                            <SplideSlide key={index}>
                                                                <img
                                                                    src={(content?.file != undefined) ? URL.createObjectURL(content.file) : (content.link ?? content.contents?.link ?? "")}
                                                                    alt={content?.file?.name ?? ''}
                                                                    height="100%"
                                                                    width="100%"
                                                                    style={{ maxHeight: '50vh' }}
                                                                    className="object-fit-contain"
                                                                />
                                                            </SplideSlide>
                                                        ) : (
                                                            <SplideSlide key={index}>
                                                                {content?.platform === PlatformType.youtube ? (
                                                                    <YouTube
                                                                        videoId={`${content?.link ?? ''}`}
                                                                        opts={opts}
                                                                        className="object-fit-contain"
                                                                        style={{
                                                                            height: "100%",
                                                                            width: "100%",
                                                                            maxHeight: '50vh'
                                                                        }}
                                                                    // onPlay={() => handleOnplayVideo(content.id)}
                                                                    />
                                                                ) : (
                                                                    <video
                                                                        controls
                                                                        width="100%"
                                                                        height="100%"
                                                                        className="object-fit-contain"
                                                                        style={{ maxHeight: '50vh' }}
                                                                        poster={(content?.thumbnail != undefined) ? URL.createObjectURL(content.thumbnail) : content.thumbnail_link ?? content.contents?.thumbnail ?? ""}
                                                                    //onPlay={() => handleOnplayVideo(content.id)}
                                                                    >
                                                                        <source src={(content?.file != undefined) ? URL.createObjectURL(content.file) : content.link ?? content.contents?.link ?? ""} type="video/mp4" />
                                                                        Your browser does not support the video tag.
                                                                    </video>
                                                                )}
                                                            </SplideSlide>
                                                        );
                                                    })}
                                                </Splide>
                                            )}
                                            <CardContent
                                                sx={{
                                                    pl: 2.5, pr: 2,
                                                    pt: 0,
                                                    '&:last-child': { pb: '16px' },
                                                }}
                                            >

                                                <Grid
                                                    container
                                                    direction='row'
                                                    justifyContent='space-between'
                                                    alignItems='center'
                                                >
                                                    <Grid item>
                                                        <Typography
                                                            variant='body1'
                                                            gutterBottom
                                                            sx={{ color: AppColors.blackColor, fontSize: "medium", fontWeight: '500' }}
                                                        >
                                                            {formik.values.title.trim().length > 0 ? formik.values.title : "Add title for the post"}
                                                        </Typography>
                                                        <Typography
                                                            variant='body2'
                                                            color='text.secondary'
                                                            className='link'
                                                            sx={{ whiteSpace: 'pre-line', color: AppColors.grayTwoColor }}
                                                        >
                                                            <ReactReadMoreReadLess
                                                                charLimit={200}
                                                                readMoreText={"Read more"}
                                                                readLessText={"Read less"}
                                                                readMoreClassName="read-more-less-more"
                                                                readLessClassName="read-more-less-less"
                                                            >
                                                                {formik.values.description.trim().length > 0 ? formik.values.description : "Add description for your post"}
                                                            </ReactReadMoreReadLess>

                                                        </Typography>

                                                    </Grid>
                                                </Grid>

                                                <Box
                                                    display='flex'
                                                    mt={2}
                                                >
                                                    <Stack
                                                        direction='row'
                                                        color='grey'
                                                        alignItems='center'
                                                        gap={1}
                                                        sx={{ mr: 3 }}
                                                    >
                                                        <Box
                                                            component={'img'}
                                                            src={HeartIcon}
                                                            sx={{
                                                                height: '1.5rem',
                                                                width: '1.5rem'
                                                            }}
                                                            style={{
                                                                height: '1.5rem',
                                                                width: '1.5rem',
                                                                color: AppColors.primaryColor,
                                                            }}
                                                        />

                                                        <Typography
                                                            variant='body2'
                                                            component='div'
                                                            sx={{ color: AppColors.grayTwoColor }}
                                                        >
                                                            0
                                                        </Typography>
                                                    </Stack>

                                                    <Stack
                                                        direction='row'
                                                        color='grey'
                                                        alignItems='center'
                                                        gap={1}
                                                        sx={{ mr: 3 }}
                                                    >
                                                        <Box
                                                            component={'img'}
                                                            src={CommentIcon}
                                                            sx={{
                                                                height: '1.5rem',
                                                                width: '1.5rem',
                                                            }}
                                                        />

                                                        <Typography
                                                            variant='body2'
                                                            component='div'
                                                            sx={{ color: AppColors.grayTwoColor }}
                                                        >
                                                            0
                                                        </Typography>
                                                    </Stack>
                                                    <Stack
                                                        direction='row'
                                                        color='grey'
                                                        alignItems='center'
                                                        gap={1}
                                                        sx={{ mr: 3 }}
                                                    >
                                                        <Box
                                                            aria-haspopup="true"
                                                        >
                                                            <Box
                                                                component={'img'}
                                                                src={ShareIcon}
                                                                sx={{
                                                                    height: '1.5rem',
                                                                    width: '1.5rem',
                                                                }}
                                                            /></Box>

                                                        <Typography
                                                            variant='body2'
                                                            component='div'
                                                            sx={{ color: AppColors.grayTwoColor }}
                                                        >
                                                            0
                                                        </Typography>
                                                    </Stack>
                                                    <Stack
                                                        direction='row'
                                                        color='grey'
                                                        alignItems='end'
                                                        justifyContent={'flex-end'}
                                                        gap={1}

                                                        sx={{ width: '100%' }}
                                                    >

                                                        <Box
                                                            component={'img'}
                                                            src={EyeIcon}
                                                            style={{
                                                                height: '1.5rem',
                                                                width: '1.5rem'
                                                            }}
                                                        />

                                                        <Typography
                                                            variant='body2'
                                                            component='div'
                                                            sx={{ color: AppColors.grayTwoColor }}
                                                        >
                                                            0
                                                        </Typography>
                                                    </Stack>
                                                </Box>

                                            </CardContent>
                                        </Stack>
                                    </Card>
                                </Grid>
                                <Grid xs={12} md={6} item>
                                    <Card
                                        key={'card-add-business-social-post'}
                                        sx={{
                                            margin: "3%",
                                            p: 2.2
                                        }}
                                        elevation={0}
                                    >
                                        <CardContent
                                            sx={{
                                                paddingTop: '0px',
                                                paddingLeft: '0px',
                                                paddingRight: '0px',
                                                paddingBottom: '0px !important',
                                                '&:last-child': { pb: '16px' },
                                            }}
                                        >

                                            <Box display="flex" alignItems="center" height="60px" marginBottom={4}>
                                                <Box width="70px">
                                                    <Avatar alt="pic" src={userInfo?.profile_picture ?? "/static/images/placeholder_profile.png"} sx={{
                                                        height: '55px', width: '55px',
                                                    }} />
                                                </Box>
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    alignItems="flex-start"
                                                    justifyContent="center"
                                                    flex={1}
                                                >
                                                    <Typography
                                                        variant="h6"
                                                        fontWeight={"500"}
                                                        component="div"
                                                        color="primary"
                                                        sx={{ color: AppColors.primaryColor }}
                                                        className="post-main-title"
                                                    >
                                                        {editBusinessSocialPostInfo?.id != null ? "Edit" : "Add"} Business Post
                                                    </Typography>

                                                    <Typography
                                                        variant="h6"
                                                        component="div"
                                                        sx={{ color: AppColors.grayThreeColor }}
                                                        fontSize="10px"
                                                        className="post-main-sub-title"
                                                    >
                                                        Share a captivating post with your prospects!
                                                    </Typography>
                                                </Box>

                                            </Box>
                                            <Grid
                                                container
                                                display={'flex'}
                                                direction='row'
                                                alignItems='center'
                                                justifyContent={'space-between'}
                                            >
                                                <Grid item xs={12} sm={12}>
                                                    <Typography marginBottom={0.5} sx={{ color: AppColors.grayThreeColor }}>Add a Title</Typography>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            id="title"
                                                            name={`title`}
                                                            placeholder='Add title for the post'
                                                            value={
                                                                formik.values.title ?? ""
                                                            }
                                                            onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                                            onChange={formik.handleChange}
                                                            fullWidth
                                                            InputProps={{ sx: inputTextFieldStyle() }}
                                                            variant="outlined"
                                                            error={Boolean(formik.errors.title && formik.touched.title)}
                                                            helperText={
                                                                Boolean(formik.errors.title && formik.touched.title) &&
                                                                formik.errors.title
                                                            }
                                                        />
                                                    </Grid>

                                                    <Typography marginTop={2} marginBottom={0.5} sx={{ color: AppColors.grayThreeColor }}>Description</Typography>
                                                    <Grid item xs={12} sm={12}>
                                                        <TextField
                                                            id="description"
                                                            name={`description`}
                                                            placeholder='Add description for your post'
                                                            value={
                                                                formik.values.description ?? ""
                                                            }
                                                            onInput={(e: any) => { e.target.value = getCapitalizedString(e.target.value ?? ""); }}
                                                            onChange={formik.handleChange}
                                                            fullWidth
                                                            InputProps={{ sx: inputTextFieldStyle() }}
                                                            multiline
                                                            maxRows={6}
                                                            variant="outlined"
                                                            error={Boolean(formik.errors.description && formik.touched.description)}
                                                            helperText={
                                                                Boolean(formik.errors.description && formik.touched.description) &&
                                                                formik.errors.description
                                                            }
                                                        />
                                                    </Grid>

                                                    {formik.values.items.length < 4 && <Grid item xs={12} sm={12}>

                                                        <input type="file" id="file" ref={fileUploader} onClick={(e: any) => e.currentTarget.value = ""} onChange={onFileChange} style={{ display: "none" }} accept={[...acceptImageFileTypes, ...(changeThumnail == undefined ? acceptVideoFileTypes : [])].join(',')} multiple />
                                                        <List>
                                                            <ListItem disablePadding>
                                                                <ListItemButton onClick={() => (isProUser || isKnoCardSocialConnectUser) ? selectMedia() : onOpenProView()}>

                                                                    <ListItemIcon>
                                                                        <CameraAltOutlined sx={{ color: AppColors.grayThreeColor }} />
                                                                    </ListItemIcon>
                                                                    <ListItemText primary="Add Media" sx={{ color: AppColors.grayThreeColor }} />
                                                                    {(!isProUser && !isKnoCardSocialConnectUser) && <ListItemIcon>
                                                                        <Box component={'img'}
                                                                            src={'/static/images/lock-yellow.svg'}
                                                                            alt={"lock"}
                                                                            style={{ height: '30px' }}
                                                                        />
                                                                    </ListItemIcon>}
                                                                </ListItemButton>
                                                            </ListItem>
                                                            <Divider
                                                                sx={{
                                                                    mt: 0,
                                                                    borderStyle: "dashed",
                                                                }}
                                                            />
                                                            <Divider
                                                                sx={{
                                                                    mt: 0,
                                                                    borderStyle: "dashed",
                                                                }}
                                                            />
                                                        </List>

                                                    </Grid>}

                                                    <Box sx={{ mt: 2, mb: 2 }}>
                                                        <MediaLibraryView isShowTitle={true} isExpanded={isMediaLibraryExpanded} setExpanded={setExpandMediaLibrary} onSelectMedia={handleOnMediaSelectFromMediaLibrary} />
                                                    </Box>

                                                    {!isMediaLibraryExpanded && (<Stack>
                                                        <DragDropContext onDragEnd={onDragEnd}>
                                                            <Droppable droppableId="droppable">
                                                                {(provided, snapshot) => (
                                                                    <div {...provided.droppableProps}
                                                                        ref={provided.innerRef}>
                                                                        {formik.values?.items.map((item, idx) => (
                                                                            <Draggable key={`item-${idx}`} draggableId={`item-${idx}`} index={idx}>
                                                                                {(provided, snapshot) => (
                                                                                    <div
                                                                                        {...provided.draggableProps}
                                                                                        {...provided.dragHandleProps}
                                                                                        ref={provided.innerRef}

                                                                                    >
                                                                                        <Fragment key={idx} >
                                                                                            <Stack alignItems="flex-end" spacing={1.5} sx={{ backgroundColor: '#ffffff' }}>
                                                                                                <Grid container spacing={3} paddingRight={'4px'}>

                                                                                                    <Grid xs={11} sm={11} item
                                                                                                        direction="row"
                                                                                                        justifyContent="center"
                                                                                                        alignItems="center">
                                                                                                        <img
                                                                                                            src={(item?.thumbnail != undefined || item?.file != undefined) ? URL.createObjectURL(item?.thumbnail ? item.thumbnail! : item.file!) : `${item.thumbnail_link ?? `${item.link ?? ""}`}`}
                                                                                                            alt={item?.file?.name}
                                                                                                            height={'64px'}
                                                                                                            width={'100%'}
                                                                                                            style={{ objectFit: "contain", paddingTop: '8px', paddingBottom: '8px' }}
                                                                                                        />
                                                                                                    </Grid>
                                                                                                    <Stack
                                                                                                        justifyContent={'center'}
                                                                                                        alignContent={'center'}
                                                                                                        alignItems={'center'} >
                                                                                                        <ReorderIcon sx={{ color: AppColors.grayThreeColor }} />
                                                                                                    </Stack>
                                                                                                </Grid>
                                                                                                <Box justifyContent="flex-end">
                                                                                                    {item.type == MediaType.video && item.platform == PlatformType.local && <Button
                                                                                                        size="small"
                                                                                                        color="primary"
                                                                                                        sx={{ marginTop: '0px !important', marginBottom: '12px !important', marginRight: '5px' }}
                                                                                                        startIcon={
                                                                                                            <Iconify icon="eva:image-outline" />
                                                                                                        }
                                                                                                        onClick={() => changeThumbnailClicked(item)}
                                                                                                    >
                                                                                                        Change Thumbnail
                                                                                                    </Button>}

                                                                                                    <Button
                                                                                                        size="small"
                                                                                                        color="error"
                                                                                                        sx={{ marginTop: '0px !important', marginBottom: '12px !important' }}
                                                                                                        startIcon={
                                                                                                            <Iconify icon="eva:trash-2-outline" />
                                                                                                        }
                                                                                                        onClick={() => removeMediaObject(item)}
                                                                                                    >
                                                                                                        remove
                                                                                                    </Button>

                                                                                                </Box>



                                                                                            </Stack>
                                                                                            <Divider
                                                                                                sx={{
                                                                                                    mt: 0,
                                                                                                    borderStyle: "dashed",
                                                                                                }}
                                                                                            />
                                                                                        </Fragment>
                                                                                    </div>

                                                                                )}
                                                                            </Draggable>
                                                                        ))}
                                                                        {provided.placeholder}
                                                                    </div>
                                                                )}
                                                            </Droppable>
                                                        </DragDropContext>
                                                        {isVideoProcessing && (<Stack direction={'column'} alignItems={'center'} justifyContent={'center'} sx={{ pt: 3, pb: 2 }}>
                                                            <CircularProgress />
                                                            <Typography sx={{ color: "gray", mt: 0.5 }}>Please wait...</Typography>
                                                        </Stack>)}
                                                    </Stack>)}

                                                    <Stack
                                                        width={'100%'}
                                                        direction={'row'}
                                                        justifyContent={'end'}
                                                        marginTop={3}
                                                    >
                                                        <LoadingButton
                                                            type="button"
                                                            loading={false}
                                                            variant="text"
                                                            sx={{ marginRight: 2 }}
                                                            onClick={onCancel}
                                                        >
                                                            Cancel
                                                        </LoadingButton>

                                                        <LoadingButton
                                                            type="submit"
                                                            loading={formik.isSubmitting}
                                                            variant="contained"
                                                        >Save</LoadingButton>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                            </Grid>}
                        </Stack>
                    </FormikProvider>
                </form >
                <BlockOverlay isBlock={toggleProView} onCloseProView={onCloseProView} />
            </Page >
        </>
    );
};
export default AddEditBusinessSocial;
