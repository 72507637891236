import Dialog from '@mui/material/Dialog';
import {
  Avatar,
  Box,
  DialogActions,
  // Button,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { AppColors, config } from '../../utils/utils';
import {
  getCommunityMediaSignInLink,
  getOSLink,
  isMobile,
} from '../../utils/getMobileOperatingSystem';
import CloseIcon from '@mui/icons-material/Close';
import { encryptString } from '../../utils/url';
import { useAppSelector } from '../../store';
import { LoadingButton } from '@mui/lab';

interface IProps {
  toggle: boolean;
  communityPostId: number;
  userName: string;
  deepLink: string | undefined | null;
  onClose: () => void;
}

const SocialDailog: React.FC<IProps> = ({
  toggle,
  communityPostId,
  userName,
  deepLink,
  onClose,
}) => {

  const referenceUserName = useAppSelector((state) => state.userInfo.sbUser?.username ?? state.userInfo.data?.username) ?? '';


  return (
    <Dialog
      open={toggle}
      onClose={() => {
        onClose();
      }}
      aria-labelledby='alert-dialog-title-2'
      aria-describedby='alert-dialog-description-2'
      maxWidth='md'
      fullWidth
      fullScreen={(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) < 500}
    >
      <IconButton
        aria-label='close'
        onClick={onClose}
        sx={{ position: 'absolute', right: 0, zIndex: 100000 }}
      >
        <CloseIcon sx={{ color: "#FFFFFF" }} />
      </IconButton>
      <DialogContent
        sx={{
          padding: 0,
          overflowX: 'hidden',
          //overflowY: window?.innerWidth > 380 ? 'hidden' : 'auto',
          backgroundImage: "linear-gradient(0deg,  #FFFFFF, #599DE8)",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      >

        <Box
        sx={{
          background: `url(/static/images/joinknocard/background.jpeg)`,
          margin:{xs:'20px',md:'45px'},
          borderRadius:"20px",
          backgroundSize:'cover',
          paddingBottom:{xs:'130px',md:'150px'},
          marginBottom:"50px",
          position:"relative"
        }}
        >
          <Box component={'img'} src="/static/images/joinknocard/persons.png"
          sx={{height:{xs:"150px",md:'200px'},
            position:'absolute',
            bottom:{xs:-30,md:-40},
            right:0
          }}/>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          <Box
            component={'img'}
            src='/static/images/knocard_360.png'
            alt='logo'
            sx={{ maxWidth: "30%", width: "100px", mt: 2 }}
          />
        </Box>
       
        <Box
          display='flex'
          flexDirection={'column'}
          alignItems='center'
          justifyContent='center'
          sx={{ padding: '0px 24px' }}
          mt={1.5}
          pl={1}
          pr={1}
        >
          <Typography
            style={{
              width: "100%",
              fontSize: '1.3rem',
              color: '#000000',
              textAlign: 'center',
            }}
          >
            Join KnoCard Social
          </Typography>
        
          <Box component={'ul'}
          sx={{
            mt:1.5,
            mb:1.5,
            li:{
              listStyleImage: "url(/static/images/joinknocard/mark.png)"
            }
          }}
          >
              <li>
              <Typography
            style={{
              width: "100%",
              fontSize: '1.2rem',
              color: AppColors.grayTwoColor,
              textAlign: 'start',
              paddingLeft:"5px"
            }}
          >No Kids</Typography>
              </li>
              <li><Typography
            style={{
              width: "100%",
              fontSize: '1.2rem',
              color: AppColors.grayTwoColor,
              textAlign: 'start',
               paddingLeft:"5px"
            }}
          >No Chaos</Typography></li>
              <li><Typography
            style={{
              width: "100%",
              fontSize: '1.2rem',
              color: AppColors.grayTwoColor,
              textAlign: 'start',
               paddingLeft:"5px"
            }}
          >No Politics</Typography></li>
              <li><Typography
            style={{
              width: "100%",
              fontSize: '1.2rem',
              color: AppColors.grayTwoColor,
              textAlign: 'start',
               paddingLeft:"5px"
            }}
          >Strictly Business</Typography></li>
          </Box>

          <Typography
            style={{
              width: "100%",

              fontSize: window?.innerWidth > 500 ? '1.3rem' : '1.2rem',
              color: '#000000',
              textAlign: 'center'
            }}
          >
            A platform designed for business professionals
          </Typography>
        </Box>
        </Box>

        <Box
        sx={{
          mt:{xs:'40px',md:'60px'},
          marginLeft:{xs:'20px',md:'45px'},
          marginRight:{xs:'20px',md:'45px'}
        }}
        >
 <Box

    display='flex' 
    alignItems='center' 
    justifyContent='center'
    mt={1.5}
>

<Box
  component={'a'} href={`${config.VBC_URL}/register${referenceUserName ? `/${referenceUserName}` : ''}?url=${encryptString(`${userName}/community/${communityPostId}`)}&${config.IS_SOCIAL_REGISTER}=true`}
  sx={{
    width: "90%",
    pt: 1,
    pb: 1,
    borderRadius: "10px",
    textAlign: "center", backgroundImage: "linear-gradient(90deg, #F1C642,  #F67837)",
  }}
>
  <Typography
    style={{
      fontSize: '1rem',
      color: '#ffffff',
    }}
  >
    Get Started for FREE
  </Typography>
</Box>
</Box>

<Box display='flex' alignItems='center' justifyContent='center' mt={1}>
<Box
  component={'a'}
  href={`${config.VBC_URL}/login?url=${encryptString(`${userName}/community/${communityPostId}`)}`}
  sx={{
    width: "90%",
    pt: 1,
    pb: 1,
    textAlign: "center",
    border: "solid 2px #F1C642",
    borderRadius: "10px",
    mb: 2,
  }}
>
  <Typography
    style={{
      fontSize: '1rem',
      color: '#000000',
    }}
  >
    I already have an account
  </Typography></Box>
</Box>
        </Box>

      </DialogContent>
      <DialogActions>
        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          sx={{
            width: "100%"
          }}
        >
          <img
            src='/static/images/joinknocard/knocard.png'
            alt='logo'
            height={"30px"}
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SocialDailog;
