import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { useNavigate, useParams } from "react-router-dom";

import styles from "../../styles/PricingSummary.module.css";
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Link,
  Container,
  Grid,
  Typography,
  Stack,
  Button,
} from '@mui/material';
// components
import Page from '../Page';
import RegisterForm from './RegisterForm';
import { getItem, setItem } from '../../utils/localStorage';
import { config } from '../../utils/utils';
import { useEffect, useRef, useState } from 'react';
import { INotFoundParams } from '../../enum';
import useWindowSize from '../../utils/useWindowSize';
import { decryptString } from '../../utils/url';
import { getPlanLabel, getSubscriptionPrice } from '../../utils/utilMethods';
import { AffiliateGroup, AffiliateGroupPricing } from '../../store/AffiliateGroup/api/interface';
import { useAppSelector } from '../../store';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {

  },
}));

const ContentStyle = styled('div')(({ theme }) => ({

  display: 'flex',

  flexDirection: 'column',

  padding: theme.spacing(1, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const params = useParams<INotFoundParams>();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const planType = getItem(config.SLECTED_PLAN_KEY);
  const isAnnually = getItem(config.SLECTED_PLAN_IS_ANNUALLY_KEY);
  const { data: affiliateGroupList, affiliateGroupPricing } = useAppSelector((state) => state.affiliateGroup);
  let [isKnoCardLinkSelected, setIsKnoCardLinkSelected] = useState(getItem(config.IS_KNOCARD_LINK_SELECTED_KEY));

  const [selectedAffiliateGroup, setSelectedAffiliateGroup] = useState<AffiliateGroup | undefined>()

  const [isSocialConnect, setIsSocialConnect] = useState<boolean>(false)

  const [selectedAffiliateGroupPricing, setSelectedAffiliateGroupPricing] = useState<AffiliateGroupPricing | undefined>()

  const planPrice = getSubscriptionPrice(planType, isAnnually, selectedAffiliateGroupPricing);
  let knoCardLinkPrice = ((isKnoCardLinkSelected && planType == config.PRO_PRO && !isAnnually) ? getSubscriptionPrice(config.KNOCARD_LINK, isAnnually, selectedAffiliateGroupPricing) : 0);




  useEffect(() => {
    if (searchParams.get(config.IS_SOCIAL_REGISTER) == 'true') {
      setIsSocialConnect(true);
    } else {
      if (!([config.FREE, config.PRO_1, config.PRO_PRO, config.PRO_BASIC, config.PRO_PLUS].includes(planType))) {
        navigate(`/subscription/${params?.username ?? ''}`);
      }
    }

    let url = searchParams.get('url');
    if (url != null) {
      setItem(config.SIGNUP_REDIRECT_KEY, `${decryptString(url)}`)
    }

  }, []);

  const windowSize = useWindowSize();
  const registrationFormRef = useRef(null);
  const [registrationFormHeight, setRegistrationFormHeight] = useState(0);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  // Function to get the height
  const getHeight = () => {
    if (registrationFormRef.current) {
      const height = (registrationFormRef?.current as any)?.clientHeight ?? 0;
      setRegistrationFormHeight(height);
    }
  };

  useEffect(() => {
    getHeight();
  }, [windowSize]);


  useEffect(() => {
    let isPricingSet = false;
    if (affiliateGroupList.length > 0) {
      let selectedId = getItem(config.GROUP_NETWORK_ID_KEY);

      if (selectedId != null) {
        affiliateGroupList.forEach((item) => {
          if (`${item.id}` == selectedId) {
            if (item.group_pricing != undefined) {
              setSelectedAffiliateGroupPricing(item.group_pricing);
              isPricingSet = true;
            }
          }
        });
      }
    }

    if (!isPricingSet) {
      affiliateGroupPricing.forEach((item) => {
        if (item.is_default == 1) {
          setSelectedAffiliateGroupPricing(item);
          isPricingSet = true;
        }
      });
    }
    if (!isPricingSet) {
      setSelectedAffiliateGroupPricing(undefined);
    }

  }, [affiliateGroupList, selectedAffiliateGroup]);


  return (
    <RootStyle title='Register | KnoCard'>
      <div className={styles.pricingSummaryContainer}>
        <div className={styles.contentContainer} ref={registrationFormRef} >
          <div className={styles.pricingSummaryHeader}>
            <div className={styles.logoContainer}>
              <Box component={"img"} src="/static/images/knocard_logo.png" alt="logo" sx={{ width: "200px" }} />
              <div className={styles.logoText}>Online</div>
            </div>
          </div>

          <div className={styles.pricingSummaryBody}>

            <RegisterForm setSelectedAffiliateGroup={setSelectedAffiliateGroup} isSocialConnect={isSocialConnect} />

            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={2}
              width={'100%'}
              justifyContent={'center'}
            >
              <Box
                width={'100%'}
                display={{ xs: 'none', sm: 'block' }}
              />
              <Typography
                variant='subtitle2'
                color={'grey'}
                sx={{
                  mt: 3,
                  textAlign: 'center',
                  width: '100%',
                }}
              >
                Already a member?&nbsp;
                <Link
                  underline='none'
                  component={Button}
                  onClick={() => navigate('/login')}
                  textTransform={'none'}

                // to='/login'
                // component={RouterLink}
                >
                  Sign In
                </Link>
              </Typography>
            </Stack>

          </div>
        </div>

        {!isSocialConnect && (<div className={styles.summaryContainer} style={{ ...(windowSize.width > 950 ? { minHeight: `${registrationFormHeight + 127}px` } : {}) }}>
          <div className={styles.summaryHeader}>Summary</div>
          <div className={styles.divider}></div>
          <div className={styles.summaryBody}>
            <div className={styles.bodyTopRow}>
              {
                planType == config.PRO_1 ? <div className={styles.packageTagPro}>
                  <span className={styles.userBadgeText}>{getPlanLabel(config.PRO_1)}</span>
                  <img className={styles.badgeIcon} src="/static/images/crown.svg" alt="userBadge" />
                </div> : planType == config.PRO_PRO ? <div className={styles.packageTagPro}>
                  <span className={styles.userBadgeText}>{getPlanLabel(config.PRO_PRO)}</span>
                </div> : planType == config.PRO_BASIC ? <div className={styles.packageTagPro}>
                  <span className={styles.userBadgeText}>{getPlanLabel(config.PRO_BASIC)}</span>
                </div> : planType == config.PRO_PLUS ? <div className={styles.packageTagPro}>
                  <span className={styles.userBadgeText}>{getPlanLabel(config.PRO_PLUS)}</span>
                </div> : <div className={styles.packageTagFree}>
                  <span>{getPlanLabel(config.FREE)}</span>
                </div>}

              <div className={styles.bodyTopRowText}>KnoCard {getPlanLabel(planType)}</div>
            </div>
            <div className={styles.bodyrow}>
              <div className={styles.subtitle}>Subscription</div>
            </div>
            <div className={styles.bodyrow}>
              <div className={styles.subtitle}>{planType == config.PRO_1 ? "Initial payment" : isAnnually ? "Annually" : "Monthly"} billing</div>
              <div className={styles.subtitle}>US ${planPrice}</div>
            </div>

            {(isKnoCardLinkSelected && planType == config.PRO_PRO && !isAnnually) && (<div className={styles.bodyrow}>
              <div className={styles.subtitle}>KnoCard Link {isAnnually ? "Annually" : "Monthly"} billing</div>
              <div className={styles.subtitle}>US ${knoCardLinkPrice}</div>
            </div>)}

          </div>
          <div className={styles.divider}></div>
          <div className={styles.summaryFooterRow}>
            <div
              className={styles.subtitle}
              style={{ color: "rgb(115, 115, 115)" }}
            >
              Subtotal
            </div>
            <div
              className={styles.subtitle}
              style={{ color: "rgb(115, 115, 115)" }}
            >
              US ${planPrice + knoCardLinkPrice}
            </div>
          </div>
          <div className={styles.summaryFooterRow}>
            <div className={styles.total}>TOTAL</div>
            <div className={styles.total}>US ${planPrice + knoCardLinkPrice}</div>
          </div>

        </div>)}
      </div>
    </RootStyle >
  );
}
