import { useFormik, Form, FormikProvider } from 'formik';
import {
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import Compressor from 'compressorjs';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';

import IOSSwitch from '../components/IOSSwitch';
import { useEffect, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import PhoneInput from 'react-phone-input-2';

import { checkEmailAddress, checkUsername } from '../store/register/api';
import { getProfile } from '../store/auth/api';


import _ from 'lodash';
import useToggle from '../hooks/useToggle';
import {
  saveProfileContact,
  saveUserProfile,
  setHomePage,
  togglePhoneNumber,
  updateFeatures,
  updateMetaTags,
} from '../store/profile/api';
import EditProfileSchema from '../validation/editProfileSchema';
import { isNotEmpty } from '../utils/valid';
import { AppColors, config } from '../utils/utils';
import { useDropzone } from 'react-dropzone';
import { uploadMediaCloudFlare } from '../store/mediaUpload/api';
import { BackgroundType } from '../enum';
import { splitString } from '../utils/splitString';
import RenderCovor from '../components/VbcComponents/RenderCover';
import BackgroundSelectionModel from '../components/BackgroundSelectionDialog';
import { FEATURES_LIST } from '../constant';
import { AffiliateGroup } from '../store/AffiliateGroup/api/interface';
import { AffiliateGroupState } from '../store/selectAffiliateGroupState/api/interface';
import AffiliateGroupFilter from '../components/AffiliateGroupFilter';
import AffiliateGroupStateFilter from '../components/AffiliateGroupStateFilter';
import { checkIsProUser } from '../utils/checkPro';


import { getItem, setItem } from '../utils/localStorage';
import { inputTextFieldStyle, showErrorMessage, showSuccessMessage } from '../utils/utilMethods';
import { updateUserProfile } from '../store/auth/authSlice';
import CalendarIcon from '../assets/profile/calendar.svg';
import CallIcon from '../assets/profile/call.svg';
import LocationIcon from '../assets/profile/location.svg';
import StateIcon from '../assets/profile/state.svg';
import GroupIcon from '../assets/profile/group.svg';
import MailIcon from '../assets/profile/mail.svg';
import PersonIcon from '../assets/profile/person.svg';
import UserNameIcon from '../assets/profile/user_name.svg';
import LinkIcon from '../assets/profile/link.svg';
import RoleIcon from '../assets/profile/role.svg';
import DownloadAppMessageModel from '../components/modal/DownloadAppMessageModel';



const UserProfile = () => {
  const dispatch = useDispatch<AppDispatch>();

  const { user } = useAppSelector((state) => state?.auth);
  const accessToken = getItem(config.AUTH_KEY);
  const isProUser = checkIsProUser(user);
  const background_image = useAppSelector((state) => state.auth.user?.knocard?.background_image) ?? '';
  const [coverType, setCoverType] = useState(splitString(background_image));

  const {
    getRootProps: profilePictureGetRootProps,
    getInputProps: profilePictureGetInputProps,
    open: profilePictureOpenDropZone,
    acceptedFiles: profilePictureAcceptedFiles,
  } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: false,
  });

  const [profilePicture, setProfilePicture] = useState<File | undefined>(
    undefined
  );
  const [backgroundImage, setBackgroundImage] = useState<
    File | string | undefined
  >(undefined);

  const [metaTag, setMetaTag] = useState('');
  const [metaTagList, setMetaTagList] = useState<string[]>([]);

  const [selectedFeature, setSelectedFeature] = useState('');
  const [featureList, setFeatureList] = useState(new Map());
  const [featureCheckList, setFeatureCheckList] = useState<any[]>([]);

  const handleMetaTagKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      if (isNotEmpty(metaTag.trim()) && !metaTagList.includes(metaTag.trim())) {
        if (!isProUser && metaTagList.length >= 3) {
          showErrorMessage(
            'You have added your maximum meta tags. Upgrade to Pro to unlock additional keywords.'
          );
          return;
        }
        const _array = [...metaTagList];
        _array.push(metaTag.trim());
        setMetaTagList(_array);
      }

      setMetaTag('');
    }
  };

  const handleChangeFeature = (event: SelectChangeEvent) => {
    setSelectedFeature(event.target.value as string);
  };

  const handleChange =
    (index: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const _array = [...featureCheckList];
      _array[index] = {
        ...featureCheckList[index],
        ...{ selected: event.target.checked },
      };

      setFeatureCheckList(_array);
    };

  const [affiliateGroup, setAffiliateGroup] = useState<AffiliateGroup | undefined>();
  const [affiliateGroupState, selectAffiliateGroupState] =
    useState<AffiliateGroupState | null>(null);

  const { toggle, onOpen, onClose } = useToggle();

  const { toggle: toggleDownloadApp, onOpen: onOpenDownloadApp, onClose: onCloseDownloadApp } = useToggle();

  const navigate = useNavigate();


  const [isNavigateToDashboard, setNavigateToDashboard] = useState<boolean>(false);

  const [activePurchaseType, setActivePurchaseType] = useState<
    string | undefined
  >(undefined);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      username: '',
      email: '',
      phoneCountryCode: '',
      mobileNumber: '',
      occupation: '',
      businessLandline: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      zipCode: '',
      showPhoneNumber: true,
      displayPhoto: true,
      calendarLink: '',
      //landing_page_link: '',
      buy_now_link:'',
      //company_buy_link:''
    },
    validationSchema: EditProfileSchema,
    onSubmit: async () => {
      if (values.username !== user?.username) {
        const response = await dispatch(
          checkUsername({ username: values.username })
        ).unwrap();
        if (response.data && response.data.status !== 'success') {
          showErrorMessage('Username already taken.');
          setSubmitting(false);
          return;
        }
      }

      if (values.email !== user?.email) {
        const response = await dispatch(
          checkEmailAddress({ email: values.email })
        ).unwrap();
        if (response.data && response.data.status !== 'success') {
          showErrorMessage('Email already taken.');
          setSubmitting(false);
          return;
        }
      }

      let userProfilePicture = user?.profile_picture;

      if (profilePicture != null) {
        const uploadProfilePictureResponse = await dispatch(
          uploadMediaCloudFlare({
            resourceType: 'USER',
            resourceId: user?.id,
            image: profilePicture,
          })
        ).unwrap();
        if (
          uploadProfilePictureResponse.data &&
          uploadProfilePictureResponse.data.status === 'success'
        ) {
          userProfilePicture = uploadProfilePictureResponse.data.data.link;
          //user!.profile_picture = userProfilePicture ?? user?.profile_picture ?? '';
          setProfilePicture(undefined);
        } else if (
          uploadProfilePictureResponse.data.status === 'failed' &&
          uploadProfilePictureResponse.data.message
        ) {
          showErrorMessage(uploadProfilePictureResponse.data.message);
          setSubmitting(false);
          return;
        } else {
          showErrorMessage('Failed to upload profile picture.');
          setSubmitting(false);
          return;
        }
      }

      let userBackgroundImage =
        typeof backgroundImage === 'string' ? backgroundImage : '';

      if (backgroundImage != null && typeof backgroundImage !== 'string') {
        const uploadBackgroundResponse = await dispatch(
          uploadMediaCloudFlare({
            resourceType: 'KNOCARD',
            knocardPosition: 1,
            knocardType: BackgroundType.uploadBackground,
            resourceId: user?.id,
            image: backgroundImage,
          })
        ).unwrap();
        if (
          uploadBackgroundResponse.data &&
          uploadBackgroundResponse.data.status === 'success'
        ) {
          userBackgroundImage = uploadBackgroundResponse.data.data.link;
          //user!.knocard.background_image = userBackgroundImage;
          setBackgroundImage(undefined);
        } else if (
          uploadBackgroundResponse.data.status === 'failed' &&
          uploadBackgroundResponse.data.message
        ) {
          showErrorMessage(uploadBackgroundResponse.data.message);
          setSubmitting(false);
          return;
        } else {
          showErrorMessage('Failed to upload profile picture.');
          setSubmitting(false);
          return;
        }
      }

      // return;

      const saveUserProfileResponse = await dispatch(
        saveProfileContact({
          user_id: user?.id ?? 0,
          api_token: accessToken ?? '',
          phone_number: values.mobileNumber,
          phone_country_code:
            user?.phone_country_code ?? values.phoneCountryCode,
          email: user?.email ?? '',
          business_phone: values.businessLandline,
          business_mobile_phone: '',
          business_email_address: user?.company?.business_email_address ?? '',
          street1: values.street1,
          street2: values.street2,
          city: values.city,
          state: values.state,
          zip_code: values.zipCode,
        })
      ).unwrap();
      if (
        saveUserProfileResponse.data &&
        saveUserProfileResponse.data.status === 'success'
      ) {
      } else if (
        saveUserProfileResponse.data.status === 'failed' &&
        saveUserProfileResponse.data.message
      ) {
        showErrorMessage(saveUserProfileResponse.data.message);
        setSubmitting(false);
        return;
      } else {
        showErrorMessage('User updating failed.');
        setSubmitting(false);
        return;
      }

      const response = await dispatch(
        saveUserProfile({
          user_id: user?.id ?? 0,
          api_token: accessToken ?? '',
          first_name: values.firstName,
          last_name: values.lastName,
          email: values.email,
          business_phone: values.businessLandline,
          profile_picture: userProfilePicture ?? '',
          occupation: values.occupation,
          phone_number: values.mobileNumber ?? '',
          phone_country_code: user?.phone_country_code ?? values.phoneCountryCode,
          background_image: isNotEmpty(userBackgroundImage) ? userBackgroundImage : user?.knocard?.background_image ?? '',
          about_me: user?.about_me ?? '',
          show_profile_picture: values.displayPhoto ? 1 : 0,
          affiliate_group_id: affiliateGroup?.id,
          group_state_id: affiliateGroupState?.id,
          calendar_link: values.calendarLink,
         // landing_page_link: values.landing_page_link,
          buy_now_link: values.buy_now_link,
         // company_buy_link: values.company_buy_link
        })
      ).unwrap();
      if (response.data && response.data.status === 'success') {
      } else if (response.data.status === 'failed' && response.data.message) {
        showErrorMessage(response.data.message);
        setSubmitting(false);
        return;
      } else {
        showErrorMessage('User updating failed.');
        setSubmitting(false);
        return;
      }

      if ((values.showPhoneNumber ? 1 : 0) !== (user?.show_phone_number ?? 0)) {
        await dispatch(togglePhoneNumber({ user_id: user?.id ?? 0 })).unwrap();
      }

      // Making contact feature always selectable
      const contactFeatureIndex = _.findIndex(featureCheckList, {
        lookupId: 6,
      });
      if (contactFeatureIndex >= 0) {
        featureCheckList[contactFeatureIndex].selected = true;
      }

      // Making KnoCardSocial always selectable
      const knoCardSocialIndex = _.findIndex(featureCheckList, {
        lookupId: 9,
      });
      if (knoCardSocialIndex >= 0) {
        featureCheckList[knoCardSocialIndex].selected = true;
      }

      await dispatch(
        updateFeatures({
          user_id: user?.id ?? 0,
          api_token: accessToken ?? '',
          ids: featureCheckList
            .filter((feature) => feature.selected === true)
            .map((feature) => feature.lookupId),
        })
      ).unwrap();

      await dispatch(
        setHomePage({
          user_id: user?.id ?? 0,
          api_token: accessToken ?? '',
          homepage: parseInt(selectedFeature),
        })
      ).unwrap();
      await dispatch(
        updateMetaTags({
          user_id: user?.id ?? 0,
          api_token: accessToken ?? '',
          meta_tags: metaTagList.join(','),
        })
      ).unwrap();
      reloadUserProfile();
      showSuccessMessage('Profile saved successfully');
      setSubmitting(false);

      var value = getItem(config.DOWNLOAD_APP_DONT_SHOW_AGAIN);
      if (value != user?.username) {
        onOpenDownloadApp();
      }


    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    values,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    getFieldProps,
    resetForm,
  } = formik;

  const inputStyle = inputTextFieldStyle();

  const reloadUserProfile = () => {
    dispatch(
      getProfile({
        user_id: user?.id ?? 0,
        viewer_id: user?.id ?? 0,
      })
    )
      .unwrap()
      .then((response) => {
        if (response.data && response.data.status === 'success') {
          dispatch(
            updateUserProfile({
              user: response.data?.data?.user,
            })
          );
        }
      });
  };

  useEffect(() => {
    reloadUserProfile();

    if (getItem(config.USER_PROFILE_PAGE_KEY) === true) {
      setItem(config.USER_PROFILE_PAGE_KEY, false);
    }
  }, []);


  useEffect(() => {
    if (!toggleDownloadApp && isNavigateToDashboard && getItem(config.OPEN_DASHBOARD_KEY) == true) {
      navigate("/dashboard")
    }

    if (toggleDownloadApp) {
      setNavigateToDashboard(true);
    }
  }, [toggleDownloadApp]);


  useEffect(() => {
    if (user) {
      values.firstName = user.first_name;
      values.lastName = user.last_name;
      values.username = user.username;
      values.email = user.email;
      values.phoneCountryCode = user.phone_country_code;
      values.mobileNumber = user.phone_number;
      values.occupation = user.occupation;
      values.businessLandline = user?.company?.business_phone ?? '';
      values.street1 = user.street1 ?? '';
      values.street2 = user.street2 ?? '';
      values.city = user.city ?? '';
      values.state = user.state ?? '';
      values.zipCode = user.zip_code ?? '';
      values.showPhoneNumber = user?.show_phone_number === 1;
      values.displayPhoto = user?.show_profile_picture === 1;
      values.calendarLink = user.calendar_link ?? '';
     // values.landing_page_link = user.landing_page_link ?? '';
      values.buy_now_link = user.buy_now_link ??'';
     // values.company_buy_link = user.company_buy_link??'';
      setAffiliateGroup(user?.affiliate_group);
      selectAffiliateGroupState(user?.group_state);

      if (isNotEmpty(user.meta_tags)) {
        setMetaTagList(user.meta_tags.split(','));
      }

      if (user.user_features) {
        const featuresMap = new Map<any, any>();

        // Fill-up all features
        FEATURES_LIST.forEach((_) => {
          featuresMap.set(_.lookupId, {
            ..._,
            ...{ selected: false },
          });
        });

        user.user_features.forEach((feature) => {
          const featureIndex = _.findIndex(FEATURES_LIST, {
            lookupId: feature.lookup_id,
          });
          if (featureIndex >= 0) {
            featuresMap.set(feature.lookup_id, {
              ...FEATURES_LIST[featureIndex],
              ...{ selected: true },
            });
          }
        });

        setFeatureList(featuresMap);

        setSelectedFeature(
          user?.knocard?.homepage ??
          (Array.from(featureList.keys())[0] as string)
        );

        setFeatureCheckList(Array.from(featuresMap.values()));
      }
      setCoverType(splitString(background_image));
    }
  }, [user]);

  useEffect(() => {
    if (isNotEmpty(profilePictureAcceptedFiles)) {
      const profilePicture = profilePictureAcceptedFiles[0];
      new Compressor(profilePicture, {
        quality: 0.6,
        success(compressedFile) {
          setProfilePicture(compressedFile as File);
        },
        error(error) {
          setProfilePicture(profilePicture);
        },
      });
    }
  }, [profilePictureAcceptedFiles]);

  useEffect(() => {
    if (backgroundImage != null) {
      if (typeof backgroundImage === 'string') {
        setCoverType(splitString(backgroundImage));
      } else {
        setCoverType(
          splitString(`0|upload|${URL.createObjectURL(backgroundImage)}`)
        );
      }
    }
  }, [backgroundImage]);



  return (
    <>
      <Page
        title='Profile | KnoCard'
        showAppBar={true}
      >
        <Container maxWidth='xl'>
          <FormikProvider value={formik}>
            <Form
              autoComplete='off'
              noValidate
              onSubmit={handleSubmit}
              onReset={() => resetForm()}
            >
              <Grid
                container
                direction={'row'}
                mt={4}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  direction={'column'}
                  sx={{ p: 2 }}
                >
                  <Box sx={{ pb: 2 }}>
                    <Typography
                      sx={{ fontSize: "1.3rem" }}
                    >
                      Personal Info
                    </Typography>
                  </Box>
                  <Stack spacing={3}>
                    <Stack
                      direction={'row'}
                      spacing={{ xs: 2, md: 5 }}
                      sx={{ position: 'relative', pb: { xs: '95px', md: '110px' }, }}
                    >
                      <Card
                        sx={{
                          width: '100%',
                          height: '200px',
                          position: 'relative',
                          borderRadius: '12px'
                        }}
                      >
                        <RenderCovor props={coverType} />

                        <Button
                          variant={'contained'}
                          sx={{
                            position: 'absolute',
                            bottom: 10,
                            right: 10,
                            textTransform: 'unset',
                            borderRadius: '10px'
                          }}
                          onClick={() => {
                            onOpen();
                          }}
                        >
                          <Typography>Edit Cover</Typography>
                        </Button>
                      </Card>
                      <Box sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: '50%',
                        transform: 'translateX(-50%)',
                        marginLeft: "0px !important"
                      }}>
                        <Card
                          sx={{
                            backgroundImage: `url(${profilePicture
                              ? URL.createObjectURL(profilePicture)
                              : isNotEmpty(user?.profile_picture)
                                ? user?.profile_picture
                                : '/static/images/placeholder_profile.png'
                              })`,
                            backgroundSize: 'cover',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            width: { xs: '125px', md: '150px' },
                            height: { xs: '125px', md: '150px' },
                            borderRadius: { xs: '125px', md: '150px' },
                          }}
                        >
                          <Box
                            {...profilePictureGetRootProps({
                              className: 'dropzone',
                            })}
                          >
                            <input {...profilePictureGetInputProps()} />
                          </Box>
                        </Card>
                        <Button
                          sx={{
                            textTransform: 'unset',
                            mt: 0.5
                          }}
                          onClick={() => {
                            profilePictureOpenDropZone();
                          }}
                        >
                          <Typography>Edit Profile Picture</Typography>
                        </Button>
                      </Box>
                    </Stack>
                    <Stack
                      direction={'row'}
                      spacing={2}
                      alignItems={'center'}
                    >
                      <Typography
                        variant='subtitle1'
                        sx={{ color: AppColors.grayThreeColor }}
                      >
                        Display Photo
                      </Typography>
                      <FormControlLabel
                        label={''}
                        control={
                          <IOSSwitch
                            sx={{ m: 1 }}
                            checked={values.displayPhoto}
                            onChange={() =>
                              setFieldValue(
                                'displayPhoto',
                                !values.displayPhoto
                              )
                            }
                            color={'primary'}
                          />
                        }
                      />
                    </Stack>
                    <Grid
                      container
                      direction={'row'}
                    >
                      {/*<Grid
                        item
                        xs={12}
                        sm={6}
                        sx={{ padding: { xs: 0, sm: 1 } }}
                      >
                        <Card sx={{ padding: 1 }}>
                          <Typography
                            variant='subtitle2'
                            color={'gray'}
                            my={2}
                          >
                            Select pages you want to display
                          </Typography>*/}
                      { /*<Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            <Typography>Default Home:</Typography>

                            <Select
                              id='demo-simple-select'
                              value={selectedFeature}
                              onChange={handleChangeFeature}
                              sx={{
                                boxShadow: 'none',
                                '.MuiOutlinedInput-notchedOutline': {
                                  border: 0,
                                },
                              }}
                            >
                              {Array.from(featureList.values()).map(
                                (val: any, index) => {
                                  return (
                                    <MenuItem
                                      value={val.lookupId}
                                      disabled={
                                        _.findIndex(featureCheckList, {
                                          lookupId: val.lookupId,
                                          selected: true,
                                        }) < 0
                                      }
                                    >
                                      <Typography
                                        variant='subtitle2'
                                        color={'primary'}
                                      >
                                        {val.feature}
                                      </Typography>
                                    </MenuItem>
                                  );
                                }
                              )}
                            </Select>
                          </Stack>*/}
                      {/*
                      {featureCheckList.map((feature: any, index) => {
                        if (feature.lookupId == 6 || feature.lookupId == 9) {
                          return <></>;
                        }
                        return (
                          <Stack
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                          >
                            <Typography>{feature.feature}</Typography>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    (feature.lookupId == 6 || feature.lookupId == 9)
                                      ? true
                                      : feature.selected
                                  }
                                  onChange={
                                    (feature.lookupId != 6 && feature.lookupId != 9)
                                      ? handleChange(index)
                                      : undefined
                                  }
                                  name={feature.feature}
                                />
                              }
                              label=''
                              labelPlacement='start'
                            />
                          </Stack>
                        );
                      })}
                    </Card>
                </Grid>*/}

                    </Grid>
                  </Stack>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ mt: { xs: 0, md: 5 }, p: 2 }}
                >

                  <Grid container xs={12} gap={2} justifyContent={'center'}>
                    <Grid item xs={12} md={5.8}>
                      <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>First Name</Typography>
                      <TextField
                        fullWidth
                        autoComplete='firstName'
                        placeholder='First name'
                        sx={{}}
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box component={'img'} src={PersonIcon} />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('firstName')}
                        error={Boolean(touched.firstName && errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                      />
                    </Grid>
                    <Grid item xs={12} md={5.8}>
                      <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>First Name</Typography>
                      <TextField
                        fullWidth
                        autoComplete='lastName'
                        placeholder='Last name'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box component={'img'} src={PersonIcon} />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('lastName')}
                        error={Boolean(touched.lastName && errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                      />

                    </Grid>
                    <Grid item xs={12} md={5.8}>
                      <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Username</Typography>
                      <TextField
                        fullWidth
                        disabled={true}
                        autoComplete='username'
                        placeholder='Username'
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box component={'img'} src={UserNameIcon} />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('username')}
                        error={Boolean(touched.username && errors.username)}
                        helperText={touched.username && errors.username}
                      />
                    </Grid>
                    <Grid item xs={12} md={5.8}>
                      <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5, width: "100%" }}>Email</Typography>
                      <TextField
                        fullWidth
                        autoComplete='email'
                        type='email'
                        placeholder='Email address'
                        sx={{ width: '100%' }}
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box component={'img'} src={MailIcon} />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </Grid>

                    <Grid item xs={12} md={5.8}>
                      <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Calendar Link</Typography>
                      <TextField
                        fullWidth
                        autoComplete='calendarLink'
                        placeholder='Calendar Link'
                        helperText="Note: Your calendar link will appear with media forms and in landing page"
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box component={'img'} src={CalendarIcon} />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('calendarLink')}
                      />
                    </Grid>
                    {/* <Grid item xs={12} md={5.8}>
                      <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Landing Page Link</Typography>
                      <TextField
                        fullWidth
                        autoComplete='landing_page_link'
                        placeholder='Landing Page Link'
                        helperText="Note: This link will appear on your landing page and will open when prospect clicks on Get Started"
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box component={'img'} src={LinkIcon} />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('landing_page_link')}
                      />
                    </Grid> */}
                    <Grid item xs={12}  md={5.8}>
                      <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Buy Now/Get Started Link</Typography>
                      <TextField
                        fullWidth
                        autoComplete='buy_now_link'
                        placeholder='Buy now/get started link'
                        helperText="Note: Add link to your company site, to drive viewers to take action. Link will appear in media shares, social posts, and landing pages."
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box component={'img'} src={LinkIcon} />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('buy_now_link')}
                      />
                    </Grid>
                    {/* <Grid item xs={12}  md={5.8}>
                      <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Buy Now Link</Typography>
                      <TextField
                        fullWidth
                        autoComplete='company_buy_link'
                        placeholder='Bumpany buy link'
                        helperText="Note: This link will appear on media view"
                        InputProps={{
                          sx: inputStyle,
                          startAdornment: (
                            <InputAdornment position='start'>
                              <Box component={'img'} src={LinkIcon} />
                            </InputAdornment>
                          ),
                        }}
                        {...getFieldProps('company_buy_link')}
                      />
                    </Grid> */}
                    
                    <Grid item xs={12}>
                      <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Phone Number</Typography>
                      <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                      >
                        <Stack
                          width={'100%'}
                          direction={'row'}
                          spacing={1}
                        >
                          <PhoneInput
                            placeholder=''
                            value={values.phoneCountryCode}
                            onChange={(phone) =>
                              setFieldValue('phoneCountryCode', `+${phone}`)
                            }
                            enableSearch={true}
                            containerStyle={{
                              ...inputStyle,
                              ...{
                                borderRadius: 10,
                                width: '40%',
                                maxHeight: Boolean(
                                  touched.mobileNumber && errors.mobileNumber
                                )
                                  ? '57px'
                                  : 'auto',
                              },
                            }}
                            inputStyle={{
                              ...{
                                borderColor: AppColors.strokeColor,
                                border: `1px solid ${AppColors.strokeColor} !important`,
                                backgroundColor: AppColors.grayZeroColor,
                                width: '100%',
                                borderRadius: '4px',
                                cursor: 'default',

                              },
                            }}

                            enableClickOutside={true}
                            countryCodeEditable={false}
                            disableCountryCode={true}
                          />

                          <TextField
                            // fullWidth

                            autoComplete='mobileNumber'
                            placeholder='Mobile Number'
                            InputProps={{
                              sx: inputStyle,
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <Box component={'img'} src={CallIcon} />
                                </InputAdornment>
                              ),
                            }}
                            {...getFieldProps('mobileNumber')}
                            error={Boolean(
                              touched.mobileNumber && errors.mobileNumber
                            )}
                            helperText={
                              touched.mobileNumber && errors.mobileNumber
                            }
                          />
                        </Stack>

                        <Stack
                          width={'100%'}
                          direction={'row'}
                          spacing={1}
                          justifyContent={'space-between'}
                          alignItems={'center'}
                          my={2}
                        >
                          <Typography
                            variant='subtitle1'
                            sx={{ color: AppColors.grayThreeColor }}
                          >
                            Show Personal Cell Number
                          </Typography>
                          <FormControlLabel
                            label={''}
                            control={
                              <IOSSwitch
                                sx={{ m: 1 }}
                                checked={values.showPhoneNumber}
                                onChange={() =>
                                  setFieldValue(
                                    'showPhoneNumber',
                                    !values.showPhoneNumber
                                  )
                                }
                                color={'primary'}
                              />
                            }
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>

                </Grid>

                <Grid container xs={12} >
                  <Divider sx={{ color: 'red', width: "100%", mb: 2, mt: 2 }} />
                </Grid>
                <Grid container xs={12} gap={2} sx={{ pl: 2, pr: 2 }} justifyContent={'center'}>
                  <Grid item xs={12}>
                    <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5 }}>Are you associated with a Group/Network?</Typography>
                  </Grid>
                  <Grid item xs={12} sm={5.8} md={3.8}  >
                    <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5, width: "100%" }}>Group</Typography>
                    <AffiliateGroupFilter
                      sx={inputStyle}
                      defaultValue={affiliateGroup}
                      onAffiliateGroupSelected={setAffiliateGroup}
                      iconPath={GroupIcon}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5.8} md={3.8}  >
                    <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5, width: "100%" }}>State</Typography>
                    <AffiliateGroupStateFilter
                      sx={inputStyle}
                      defaultValue={affiliateGroupState}
                      onAffiliateGroupStateSelected={
                        selectAffiliateGroupState
                      }
                      iconPath={StateIcon}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5.8} md={3.8}>
                    <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5, width: "100%" }}>Role</Typography>
                    <TextField
                      fullWidth
                      autoComplete='occupation'
                      placeholder='Occupation'
                      InputProps={{
                        sx: inputStyle,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Box component={'img'} src={RoleIcon} />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('occupation')}
                      error={Boolean(touched.occupation && errors.occupation)}
                      helperText={touched.occupation && errors.occupation}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5.8} md={3.8}>
                    <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5, width: "100%" }}>Business Landline</Typography>
                    <TextField
                      fullWidth
                      autoComplete='businessLandline'
                      placeholder='Business Landline'
                      InputProps={{
                        sx: inputStyle,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Box component={'img'} src={CallIcon} />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('businessLandline')}
                      error={Boolean(
                        touched.businessLandline && errors.businessLandline
                      )}
                      helperText={
                        touched.businessLandline && errors.businessLandline
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={5.8} md={3.8}>
                    <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5, width: "100%" }}>Street 1</Typography>
                    <TextField
                      fullWidth
                      autoComplete='street1'
                      placeholder='Street 1'
                      InputProps={{
                        sx: inputStyle,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Box component={'img'} src={LocationIcon} />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('street1')}
                      error={Boolean(touched.street1 && errors.street1)}
                      helperText={touched.street1 && errors.street1}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5.8} md={3.8}>
                    <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5, width: "100%" }}>Street 2</Typography>
                    <TextField
                      fullWidth
                      autoComplete='street2'
                      placeholder='Street 2'
                      InputProps={{
                        sx: inputStyle,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Box component={'img'} src={LocationIcon} />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('street2')}
                      error={Boolean(touched.street2 && errors.street2)}
                      helperText={touched.street2 && errors.street2}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5.8} md={3.8}>
                    <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5, width: "100%" }}>City</Typography>
                    <TextField
                      fullWidth
                      autoComplete='city'
                      placeholder='City'
                      InputProps={{
                        sx: inputStyle,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Box component={'img'} src={LocationIcon} />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('city')}
                      error={Boolean(touched.city && errors.city)}
                      helperText={touched.city && errors.city}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5.8} md={3.8}>
                    <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5, width: "100%" }}>State</Typography>
                    <TextField
                      fullWidth
                      autoComplete='state'
                      placeholder='State'
                      InputProps={{
                        sx: inputStyle,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Box component={'img'} src={LocationIcon} />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('state')}
                      error={Boolean(touched.state && errors.state)}
                      helperText={touched.state && errors.state}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5.8} md={3.8}>
                    <Typography sx={{ color: AppColors.grayThreeColor, mb: 0.5, width: "100%" }}>Zip Code</Typography>
                    <TextField
                      fullWidth
                      autoComplete='zipCode'
                      placeholder='Zip Code'
                      InputProps={{
                        sx: inputStyle,
                        startAdornment: (
                          <InputAdornment position='start'>
                            <Box component={'img'} src={LocationIcon} />
                          </InputAdornment>
                        ),
                      }}
                      {...getFieldProps('zipCode')}
                      error={Boolean(touched.zipCode && errors.zipCode)}
                      helperText={touched.zipCode && errors.zipCode}
                    />
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    // sm={6}
                    sx={{ padding: 2 }}
                  >
                    <Typography
                      variant='subtitle1'

                      sx={{ color: AppColors.grayThreeColor, marginTop: { xs: 4, sm: 0 } }}
                    >
                      Meta Tags &nbsp;
                      <Typography
                        variant='body2'
                        component={'span'}
                        sx={{ color: AppColors.grayTwoColor }}
                      >
                        (Pro Members can add up to 10 keywords for better
                        searchability)
                      </Typography>
                    </Typography>
                    {!(
                      (isProUser && metaTagList.length >= 10) ||
                      (!isProUser && metaTagList.length >= 3)
                    ) && (
                        <TextField
                          fullWidth
                          value={metaTag}
                          onChange={(e) => setMetaTag(e.target.value)}
                          variant='standard'
                          multiline={true}
                          placeholder='Add a tag'

                          // onKeyDown={handleMetaTagKeyDown}
                          onKeyUp={handleMetaTagKeyDown}
                          sx={{ mt: 1, mb: 1 }}
                          InputProps={{
                            sx: { ...inputStyle, p: 2 },
                            disableUnderline: true
                          }}
                        />
                      )}
                    <Box>
                      {metaTagList.map((item, index) => (
                        <Chip
                          key={index}
                          label={item}
                          onDelete={() => {
                            const _array = [...metaTagList];
                            setMetaTagList(
                              _array.filter((tag) => tag !== item)
                            );
                          }}
                          sx={{
                            m: 1, borderRadius: '4px', backgroundColor: AppColors.primaryColor, opacity: 0.80, color: AppColors.whiteColor, '& .MuiChip-deleteIcon': {
                              color: AppColors.whiteColor,
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </Grid>

                  <Grid xs={12} justifyContent={'center'} sx={{ textAlign: 'center', mb: 6, mt: 2 }}>
                    <LoadingButton
                      //   fullWidth

                      type='submit'
                      variant='contained'
                      loading={isSubmitting}
                      sx={{ pl: 6, pr: 6, pt: 1, pb: 1, textTransform: 'unset', fontSize: '1.0rem' }}
                    >
                      Save
                    </LoadingButton>
                  </Grid>

                </Grid>
                {/* <Grid item xs md sx={{ mt: { xs: 5, md: 0 } }}>
                  <Stack spacing={2}>
                    <Typography
                      textAlign={'center'}
                      variant='h6'
                      color={'gray'}
                      fontFamily={'trocchi-regular !important'}
                    >
                      Your{' '}
                      <Typography
                        component={'span'}
                        variant='h6'
                        fontFamily={'trocchi-regular !important'}
                        color={'#F58227'}
                      >
                        initial
                      </Typography>{' '}
                      online set- up is
                      <Typography
                        letterSpacing={1}
                        variant='h4'
                        color={'#545454'}
                        fontWeight={'400'}
                      >
                        Complete
                      </Typography>
                    </Typography>
                    <Stack
                      justifyContent={'center'}
                      alignItems={'center'}
                      alignSelf={'center'}
                      textAlign={'center'}
                      sx={{ position: 'relative' }}
                    >
                      <SetupImage
                        alt='Setup Info'
                        src='/static/images/setup_info.png'
                      />
                      <Box sx={{ position: 'absolute', top: 0, left: 10 }}>
                        <SetupStatusImage
                          alt='Right sign'
                          src='/static/images/ic_done.png'
                        />
                        <FeatureTitleText>Initial Set-up</FeatureTitleText>
                        <Box justifyContent={'center'} alignItems={'center'}>
                          <BrowserImage
                            alt='safari'
                            src='/static/images/ic_safari.png'
                          />
                          <BrowserImage
                            alt='firefox'
                            src='/static/images/ic_firefox.png'
                            style={{ marginLeft: '10px', marginRight: '10px' }}
                          />
                          <BrowserImage
                            alt='chrome'
                            src='/static/images/ic_chrome.png'
                          />
                        </Box>
                      </Box>
                      <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                        <SetupStatusImage
                          alt='Pending sign'
                          src='/static/images/ic_pending.png'
                        />
                        <FeatureTitleText>Add Media Files</FeatureTitleText>
                        <AppRequiredText>App Required</AppRequiredText>
                      </Box>
                      <Box sx={{ position: 'absolute', bottom: 0, left: 10 }}>
                        <SetupStatusImage
                          alt='Pending sign'
                          src='/static/images/ic_pending.png'
                        />
                        <FeatureTitleText>Sharing</FeatureTitleText>
                        <AppRequiredText>App Required</AppRequiredText>
                      </Box>
                      <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
                        <SetupStatusImage
                          alt='Pending sign'
                          src='/static/images/ic_pending.png'
                        />
                        <FeatureTitleText>Add a Social Post</FeatureTitleText>
                        <AppRequiredText>App Required</AppRequiredText>
                      </Box>
                    </Stack>
                    <img
                      alt='Divider'
                      src='/static/images/divider_image.png'
                      width={'100%'}
                      style={{ padding: '10px' }}
                    />
                    <Grid
                      container
                      direction={{ xs: 'column', md: 'row' }}
                      justifyContent={'space-between'}
                    >
                      <Grid item xl={6} lg={12}>
                        <Box
                          sx={{
                            backgroundColor: 'yellow',
                            borderRadius: '50px',
                            padding: '5px',
                          }}
                          textAlign={'center'}
                        >
                          <Typography variant='h6' color={'#545454'}>
                            Download Required NOW
                          </Typography>
                        </Box>
                        <Box
                          display='flex'
                          alignItems='center'
                          justifyContent='center'
                          mt={1.5}
                          mb={window?.innerWidth > 500 ? 2 : 1}
                        >
                          <a
                            href={config.APP_STORE_URL}
                            target='_blank'
                            rel='noreferrer'
                            style={{
                              textDecoration: 'none',
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <img
                              src='/static/images/app_store_image.png'
                              height={'80%'}
                              width={'80%'}
                              alt='apple-link'
                            />
                          </a>

                          <a
                            href={config.PLAY_STORE_URL}
                            target='_blank'
                            rel='noreferrer'
                            style={{
                              textDecoration: 'none',
                              display: 'flex',
                              justifyContent: 'flex-start',
                              marginLeft: '10px',
                            }}
                          >
                            <img
                              src='/static/images/google_playstore_image.png'
                              height={'80%'}
                              width={'80%'}
                              alt='google-link'
                            />
                          </a>
                        </Box>
                        <Stack
                          mt={3}
                          direction={'row'}
                          justifyContent={'center'}
                          alignContent={'center'}
                          alignItems={'center'}
                        >
                          <Stack width={'100%'}>
                            <Typography variant='body1' textAlign={'end'}>
                              A message from our CEO
                            </Typography>
                            <Stack
                              direction={'row'}
                              mt={1}
                              sx={{
                                backgroundColor: '#BABDBB',
                                borderRadius: '50px',
                              }}
                            >
                              <img
                                alt='Play video'
                                src='/static/images/ic_play_video.png'
                              />
                              <Typography
                                variant='h4'
                                color={'white'}
                                alignSelf={'center'}
                                width={'100%'}
                                textAlign={'center'}
                                fontWeight={'600'}
                                fontSize={'15px !important'}
                              >
                                Watch Video
                              </Typography>
                            </Stack>
                          </Stack>
                          <Avatar
                            sx={{
                              width: 80,
                              height: 80,
                            }}
                            alt='CEO'
                            src='/static/images/ceo_photo.png'
                            style={{ marginLeft: '5px' }}
                          />
                        </Stack>
                      </Grid>
                      <Grid
                        item
                        xl={6}
                        lg={12}
                        direction={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        textAlign={'center'}
                        sx={{ mt: { xs: 3, md: 0 } }}
                        width={'100%'}
                      >
                        <Typography
                          variant='h4'
                          color={'#555555'}
                          letterSpacing={1}
                        >
                          Text
                          <Typography
                            variant='h4'
                            component={'span'}
                            color={'primary'}
                            fontWeight={'600'}
                          >
                            {' '}
                            GETAPP{' '}
                          </Typography>
                          <Typography variant='h4' component={'span'}>
                            to
                          </Typography>
                        </Typography>
                        <Typography
                          variant='h6'
                          color={'#555555'}
                          fontWeight={'bold'}
                        >
                          216-343-3393
                        </Typography>
                        <img
                          alt='send_to_your_phone'
                          src='/static/images/send_to_your_phone.png'
                          height={'60%'}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Grid> */}
              </Grid>
            </Form>
          </FormikProvider >

          <BackgroundSelectionModel
            toggle={toggle}
            onClose={onClose}
            setBackgroundImage={setBackgroundImage}
          />

          {toggleDownloadApp && <DownloadAppMessageModel
            onClose={onCloseDownloadApp}
            toggle={toggleDownloadApp}
          />}

        </Container >
      </Page >
    </>
  );
};
export default UserProfile;
