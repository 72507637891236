import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';

import styles from '../styles/Reports.module.css';
import Page from '../components/Page';
import { AppDispatch, useAppSelector } from '../store';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { getAllReportTotal, getEngageUserActivityCount } from '../store/reports/api';
import { AppColors, config } from '../utils/utils';
import { getItem, removeItem } from '../utils/localStorage';
import { useNavigate } from 'react-router-dom';
import { checkIsProProOrProOneOrProPlusUser, checkIsProUser } from '../utils/checkPro';

import { calculateReportPercentageDiff, formatReportCount } from '../utils/utilMethods';
import { getEngagePipeline } from '../store/engagePipeline/api';
import { EngageActivity } from '../store/engagePipeline/api/interface';
import DashboardEngagePiplineItem from '../components/engage/DashboardEngagePiplineItem';
import { ChatUser } from '../store/chat/api/interface';
import { UserInfo } from '../store/userInfo/api/interface';
import { getChatUsers } from '../store/chat/api';
import UserListForMessage from '../components/Chat/ChatSidebar/UserListForMessage';
import { encryptString } from '../utils/url';
import { ReportCountItem } from '../components/Reports/ReportContItem';

const Dashboard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { user } = useAppSelector((state) => state?.auth);
  const { allReportSummary, totalEngageUserActivity } = useAppSelector((state) => state?.reports);

  const engagePipelines = useAppSelector((state) => state?.engagePipline?.data) ?? [];
  const { isLoading: isPipelineLoading } = useAppSelector(
    (state) => state?.engagePipline
  );

  const navigate = useNavigate();
  const [chatUsers, setChatUsers] = useState<ChatUser[]>([]);

  const { chatChannels, isLoading: isChatUsersLoading, userList: chatUserList } = useAppSelector((state) => state?.chat);
  const isProUser = checkIsProUser(user);
  const isProOneOrProUser = checkIsProProOrProOneOrProPlusUser(user);




  useEffect(() => {

    dispatch(getAllReportTotal({ userId: (user?.id ?? 0) })).unwrap();

    if (isProOneOrProUser && engagePipelines.length == 0) {
      dispatch(getEngagePipeline({ pageNo: 1, order_by: "desc", search_name: "", followUpScheduleFilter: "all" }));

    }

    if (isProUser) {
      dispatch(getEngageUserActivityCount()).unwrap();
    }

    let redirectUrl = getItem(config.SIGNUP_REDIRECT_KEY);
    if (redirectUrl.length > 0) {
      removeItem(config.SIGNUP_REDIRECT_KEY);
      navigate(`/${redirectUrl}`)
    }

    removeItem(config.OPEN_DASHBOARD_KEY);
  }, []);

  useEffect(() => {
    updateChatUserList();
  }, [chatUserList]);

  useEffect(() => {

    if (chatChannels.length > chatUserList.length) {
      const userIdList = chatUserList.map((item) => item.id);
      const fetchUsersIdList: number[] = [];

      chatChannels.forEach((item) => {
        try {
          if (item.users != null && item.users.length >= 2) {
            let chatUserId = (item.users[0] == user?.id) ? item.users[1] : item.users[0];
            if (!userIdList?.includes(chatUserId)) {
              fetchUsersIdList.push(chatUserId);
            }
          }
        } catch (e) { }
      });

      if (fetchUsersIdList.length > 0 && !isChatUsersLoading) {
        dispatch(getChatUsers({ user_ids: fetchUsersIdList }));
      }
    } else {
      updateChatUserList();
    }
  }, [chatChannels]);


  const updateChatUserList = () => {
    let updatedChatUsers: ChatUser[] = [];

    updatedChatUsers = loadChatInfoOnUser(chatUserList);
    updatedChatUsers.sort((itemOne, itemTwo) => {
      let valueOne = itemTwo?.lastMessage?.time?.seconds;
      let valueTwo = itemOne?.lastMessage?.time?.seconds;
      if (valueOne == undefined && valueTwo == undefined) {
        return 0;
      } else if (valueOne != undefined && valueTwo == undefined) {
        return 1;
      } else if (valueOne == undefined && valueTwo != undefined) {
        return -1;
      } else if (valueOne == undefined) {
        return -1;
      } else if (valueTwo == undefined) {
        return 1;
      } else if (valueOne > valueTwo) {
        return 1;
      } else if (valueOne < valueTwo) {
        return -1;
      }
      return 0;
    });




    setChatUsers(updatedChatUsers);
  }


  const loadChatInfoOnUser = (userList: UserInfo[]): ChatUser[] => {

    let updatedChatUsers: ChatUser[] = [];
    userList.forEach((chatUserItem) => {
      if (chatUserItem.id != user?.id) {
        let isUserChatExist = false;
        chatChannels.forEach((chatChannelItem) => {
          if (chatChannelItem.users?.includes(chatUserItem.id)) {
            let userChatItem = {
              id: chatUserItem.id,
              chatId: chatChannelItem.id,
              name: chatUserItem.name,
              username: chatUserItem.username,
              first_name: chatUserItem.first_name,
              last_name: chatUserItem.last_name,
              lastMessageTime: chatChannelItem.updated_at,
              email: chatUserItem.email,
              profile_picture: chatUserItem.profile_picture,
              occupation: chatUserItem.occupation,
              pined_by: Array.isArray(chatChannelItem.pined_by) ? chatChannelItem.pined_by : [],
              lastMessage: chatChannelItem.last_message,
              metaTags: chatUserItem.meta_tags,
              chatChannelItem: chatChannelItem
            };
            updatedChatUsers.push(userChatItem);
            isUserChatExist = true;
          }
        });

        if (!isUserChatExist) {
          let userChatItem = {
            id: chatUserItem.id,
            name: chatUserItem.name,
            username: chatUserItem.username,
            first_name: chatUserItem.first_name,
            last_name: chatUserItem.last_name,
            email: chatUserItem.email,
            profile_picture: chatUserItem.profile_picture,
            occupation: chatUserItem.occupation,
            pined_by: [],
            metaTags: chatUserItem.meta_tags,
          };
          updatedChatUsers.push(userChatItem);
        }
      }

    });
    return updatedChatUsers;
  };


  const getUnreadMessageCount = () => {
    return chatChannels.reduce(
      (total, item) =>
      (total +=
        item?.last_message?.from != user?.id
          ? item?.last_message?.unread ?? 0
          : 0),
      0
    );
  };

  const handleNavigateToPipeline = () => {
    navigate('/dashboard/engage/pipeline');
  }

  const handleNavigateToReport = () => {
    navigate('/dashboard/reports');
  }

  const handleNavigateToTutorial = () => {
    navigate('/dashboard/help-support-video');
  }


  const handleShareHistoryClick = () => {
    navigate('/dashboard/share-history');
  }

  const handleProspectActivityClick = () => {
    navigate('/dashboard/prospect-activity');
  }





  return (
    <>
      <Page title='Dashboard | KnoCard' showAppBar={true}>
        <Container maxWidth='xl'>
          <Box className={styles.mainContent} marginTop={{ xs: 0, md: 5 }}>

            < Box className={styles.mainContentBody} >
              <Grid container gap={2} justifyContent={'center'} sx={{ mb: 6 }}>
                <Grid item md={5.5} sm={5.5} xs={12} sx={{ display: { sm: 'unset', xs: 'none' } }} >
                  <Typography sx={{ pb: 1, pt: 1, fontWeight: "500" }}>Pipeline</Typography>
                  <Box sx={{ p: 2, backgroundColor: AppColors.whiteColor, borderRadius: "10px", position: "relative" }}>
                    <Box sx={{ minHeight: { md: "200px", xs: "100px" } }}>
                      {(!isPipelineLoading && isProOneOrProUser && engagePipelines.length > 0) && [...(engagePipelines.length > 3 ? engagePipelines.slice(0, 3) : engagePipelines)].map((engageActivity: EngageActivity, idx: number) => {
                        return (<DashboardEngagePiplineItem
                          key={`engage-pipline-item-${idx}`}
                          engageActivity={engageActivity} index={idx}

                        />);
                      })
                      }

                      {!isPipelineLoading && isProOneOrProUser && engagePipelines.length == 0 && <Stack
                        display={'flex'}
                        justifyContent="center"
                        alignContent={'center'}
                        alignItems={'center'}
                        sx={{ minHeight: { md: "200px", xs: "100px" } }}
                      ><Typography sx={{ fontSize: "1rem", color: AppColors.blackColor }}> Data not found.</Typography></Stack>}

                      {isPipelineLoading && isProOneOrProUser && <Stack
                        display={'flex'}
                        justifyContent="center"
                        alignContent={'center'}
                        alignItems={'center'}
                        sx={{ height: '100px' }}
                      >
                        <CircularProgress />
                      </Stack>}

                      {!isProOneOrProUser && (<Stack
                        display={'flex'}
                        justifyContent="center"
                        alignContent={'center'}
                        alignItems={'center'}
                        sx={{ marginTop: { md: "50px", xs: "0px" }, height: '100px' }}
                      ><Box
                          component='img'
                          src='/static/images/lock-yellow.svg'
                          sx={{ height: '50%', }}
                        /></Stack>)}

                    </Box>

                    {(!isPipelineLoading && isProOneOrProUser && engagePipelines.length > 0) && (<Box sx={{ top: 0, position: "absolute", height: "100%", width: "100%" }} onClick={handleNavigateToPipeline}></Box>)}
                  </Box>

                </Grid>

                <Grid item md={5.5} sm={5.5} xs={12}>
                  <Typography sx={{ pb: 1, pt: 1, fontWeight: "500" }}>Reports</Typography>
                  <Box sx={{ p: { md: 2, xs: 0 }, backgroundColor: { md: AppColors.whiteColor, xs: 'unset' }, borderRadius: "10px", position: "relative" }}>
                    <Grid container gap={1} justifyContent={'center'}>
                      <Grid item sm={5.8} xs={5.8}>
                        <ReportCountItem title="New Prospects" previousReport={allReportSummary?.contactPreviousMonth || 0} currentReport={allReportSummary?.contactMonth || 0} isProUser={isProUser} reportType={"Month"} />
                      </Grid>
                      <Grid item sm={5.8} xs={5.8}>
                        <ReportCountItem title="Page Views" previousReport={allReportSummary?.viewsPreviousMonth || 0} currentReport={(allReportSummary?.viewsMonth || 0)} isProUser={isProUser} reportType={"Month"} />
                      </Grid>
                      <Grid item sm={5.8} xs={5.8}>
                        <ReportCountItem title="Video Views" previousReport={allReportSummary?.viewVideoMediaPostPreviousMonth || 0} currentReport={(allReportSummary?.viewVideoMediaPostMonth || 0)} isProUser={isProUser} reportType={"Month"} />

                      </Grid>
                      <Grid item sm={5.8} xs={5.8}>
                        <ReportCountItem title="Media Share" previousReport={allReportSummary?.shareMediaPreviousMonth || 0} currentReport={(allReportSummary?.shareMediaMonth || 0)} isProUser={isProUser} reportType={"Month"} />
                      </Grid>
                      <Grid item sm={5.8} xs={5.8}>
                        <ReportCountItem title="Personal Shares" previousReport={allReportSummary?.webAppSharePreviousMonth || 0} currentReport={(allReportSummary?.webAppShareMonth || 0)} isProUser={isProUser} reportType={"Month"} />
                      </Grid>
                      <Grid item sm={5.8} xs={5.8}>
                        <ReportCountItem title="Social Posts" previousReport={allReportSummary?.shareSocialPostsPreviousMonth || 0} currentReport={(allReportSummary?.shareSocialPostsMonth || 0)} isProUser={isProUser} reportType={"Month"} />
                      </Grid>
                      <Grid item sm={5.8} xs={5.8}>
                        <ReportCountItem title="Referrals" previousReport={allReportSummary?.referralsPreviousMonth || 0} currentReport={(allReportSummary?.referralsMonth || 0)} isProUser={isProUser} reportType={"Month"} />
                      </Grid>
                      <Grid item sm={5.8} xs={5.8}>
                        <ReportCountItem title="Landing Page" previousReport={allReportSummary?.shareAffiliateLandingPagePreviousMonth || 0} currentReport={(allReportSummary?.shareAffiliateLandingPageMonth || 0)} isProUser={isProUser} reportType={"Month"} />
                      </Grid>
                    </Grid>
                    <Box sx={{ top: 0, position: "absolute", height: "100%", width: "100%" }} onClick={handleNavigateToReport}></Box>
                  </Box>

                </Grid>

                <Grid item md={5.5} sm={5.5} xs={12}>
                  <Typography sx={{ pb: 1, pt: 1, fontWeight: "500" }}>Activity</Typography>
                  <Box>
                    <Grid container justifyContent={'center'}>
                      <Grid item xs={6} sx={{ pr: 1, cursor: 'pointer' }} onClick={handleShareHistoryClick}>
                        <Box sx={{ borderRadius: "10px", p: 1, backgroundColor: AppColors.whiteColor }}>
                          <Typography sx={{ pb: 1, fontWeight: "500", textAlign: 'center' }}>Share<br />History</Typography>
                          <Box component={'img'}
                            src='/static/images/share_history.svg'
                            sx={{
                              height: "100px",
                              width: '100%'
                            }}
                          />

                        </Box>
                      </Grid>
                      <Grid item xs={6} sx={{ pl: 1, cursor: 'pointer' }} onClick={handleProspectActivityClick}>
                        <Box sx={{ borderRadius: "10px", p: 1, backgroundColor: AppColors.whiteColor, position: 'relative' }}>
                          <Typography sx={{ pb: 1, fontWeight: "500", textAlign: 'center' }}>Prospect<br />Activity</Typography>
                          <Box component={'img'}
                            src='/static/images/prospect_activity.svg'
                            sx={{
                              height: "100px",
                            }}
                          />
                          {(totalEngageUserActivity > 0.0) && (<Box sx={{ position: "absolute", bottom: 10, right: 10, borderRadius: "200px", backgroundColor: AppColors.liteRedColor, p: 0.5 }}>
                            <Box sx={{ bottom: 10, right: 10, borderRadius: "200px", backgroundColor: AppColors.redColor }}>
                              <Typography sx={{ color: AppColors.whiteColor, p: 0.5, minHeight: "30px", minWidth: "30px", textAlign: 'center' }}>{formatReportCount(totalEngageUserActivity)}</Typography>
                            </Box>
                          </Box>)}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                </Grid>
                <Grid item md={5.5} sm={5.5} xs={12}>
                  <Typography sx={{ pb: 1, pt: 1, fontWeight: "500" }}>Tutorial</Typography>
                  <Box sx={{
                    p: 2,
                    backgroundColor: AppColors.whiteColor, borderRadius: "10px", position: "relative",
                    backgroundImage: 'url("/static/images/tutorial_background.svg")',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    minHeight: "200px"
                  }}

                  >
                    <Grid container justifyContent={'center'}>
                      <Grid item md={6} xs={12}>
                        <Typography sx={{ fontSize: '1.8rem', fontWeight: '500', color: AppColors.whiteColor }}>Welcome</Typography>
                        <Box component={'img'} src={'/static/images/tutorial_icon.png'} sx={{ width: "100%", display: { xs: 'unset', md: 'none' } }} />
                        <Typography sx={{ fontSize: '1.0rem', color: AppColors.whiteColor, mt: 0.5, mb: 0.5 }}>Let's take a quick tour of the benefits of your new KnoCard. This brief video will explain features that will help build, track and grow your business. Again, welcome to the KnoCard community.</Typography>
                        <Button onClick={handleNavigateToTutorial} sx={{
                          backgroundColor: AppColors.whiteColor, color: AppColors.blackColor, textTransform: 'unset', borderRadius: "12px", fontWeight: "500", width: "100%", mt: 1,
                          '&:hover': {
                            backgroundColor: AppColors.whiteColor
                          }

                        }}>Watch Now</Button>
                      </Grid>
                      <Grid item md={6} xs={0}>
                        <Box component={'img'} src={'/static/images/tutorial_icon.png'} sx={{ width: "100%", display: { xs: 'none', md: 'unset' } }} />
                      </Grid>
                    </Grid>


                  </Box>

                </Grid>

              </Grid>

            </Box >
          </Box >
        </Container >
      </Page >
    </>
  );
};
export default Dashboard;
